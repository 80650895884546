import React from "react";
//style
import spinnerStyle from "Components/Spinner/spinnerStyle.module.css";
import { ImSpinner5 } from "react-icons/im";

const SpinnerButton = () => {
  return (
    <ImSpinner5
      className={`${spinnerStyle.animateSpin} text-white text-2xl mr-2`}
    />
  );
};

export default SpinnerButton;
