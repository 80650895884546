import React from "react";

const DeleteIcon = () => (
  <div
    className="w-full flex items-center cursor-pointer
    transition fill-current duration-300 active:fill bg-blue-700 
    active:cursor-pointer hover:bg-blue-700 hover:cursor-pointer max-md:w-6"
  >
    <svg
      className="cursor-pointer"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z"
        fill="#fff"
      />
      <path d="M9 9H11V17H9V9Z" fill="#fff" />
      <path d="M13 9H15V17H13V9Z" fill="#fff" />
    </svg>
  </div>
);

const SeeMore = (seeMoreUrl, canDeleteThiStory, storyId, onDeleteStory) => (
  <div>
    {canDeleteThiStory && (
      <div
        className=" fixed flex right-9 top-4"
        onClick={() => {
          onDeleteStory(storyId);
        }}
      >
        <DeleteIcon />
      </div>
    )}
    <div
      onClick={() => {
        document.getElementById("storiesBackdrop").click();
      }}
      className="fixed flex right-3 top-4"
    >
      <span className="cursor-pointer text-primary-wastepaper-story filter drop-shadow text-lg">
        X
      </span>
    </div>
    {seeMoreUrl && (
      <div
        className=" h-vh-5 bg-story-see-more flex flex-col w-full items-center justify-end bottom-0"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          window.open(seeMoreUrl, "_blank");
        }}
      >
        <span
          className="text-white items-center tracking-widest mb-6 capitalize opacity-100 text-xs 
          cursor-pointer transition-opacity	"
        >
          ⌃
        </span>
        <span
          className="text-white items-center tracking-wide mb-5 opacity-100 
          filter drop-shadow capitalize transition-opacity cursor-pointer"
        >
          Ver más
        </span>
      </div>
    )}
  </div>
);

export default SeeMore;
