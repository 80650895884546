import React from "react";
//component
import logoAsocar from "Assets/logo-asocar.png";
import { useHistory } from "react-router";
import { ROUTES } from "Config/config";

const AppreciationPage = ({
  appreaciationMessage = "Muchas Gracias por contestar el formulario, el resultado será enviado a su correo, si en 3 minutos el correo no ha llegado, por favor revise la bandeja de spam.",
}) => {
  const history = useHistory();
  return (
    <div
      className="w-screen min-h-screen bg-sign-in-background bg-cover
bg-center flex justify-center md:pt-28 md:pb-14"
    >
      <div className="flex justify-center flex-col glass w-full lg:w-5/12 lg:h-vh-22 p-10 lg:rounded-2xl">
        <img
          alt="Logo ASOCAR"
          className="w-vw-7 max-md:w-56 self-center m-3"
          src={logoAsocar}
        />
        <span className="font-bold text-xl text-appre-color text-center m-3">
          {appreaciationMessage}
        </span>
        <button
          className={`btn my-2 w-full bg-primary-button`}
          onClick={() => history.push(ROUTES.SIGNIN)}
        >
          🙂 Volver al Login
        </button>
      </div>
    </div>
  );
};

export default AppreciationPage;
