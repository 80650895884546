import { createSelector } from "reselect";

const selectOrganization = (state) => state.organization;

export const selectedOrganizationId = createSelector(
  [selectOrganization],
  (organization) => organization.id
);

export const selectedOrganizationStyle = createSelector(
  [selectOrganization],
  (organization) => organization.cssStyle
);

export const selectedOrganizationCountryId = createSelector(
  [selectOrganization],
  (organization) => organization.countryId
);

export const selectOrganizationMembership = createSelector(
  [selectOrganization],
  (organization) => organization.membership
);

export const selectOrganizationName = createSelector(
  [selectOrganization],
  (organization) => organization.name
);
