import { createSelector } from "reselect";

const selectQuest = (state) => state.questions;

export const selectedQuestionList = createSelector(
  [selectQuest],
  (questions) => questions.questionData
);

export const selectedQuizCode = createSelector(
  [selectQuest],
  (questions) => questions.code
);

export const selectAppreciationMsg = createSelector(
  [selectQuest],
  (questions) => questions.appreciationMessage
);

export const selectQuestResult = createSelector(
  [selectQuest],
  (questions) => questions.result
);
