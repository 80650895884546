import UserActionTypes from "./user-types";

const INITIAL_STATE = {
  currentUser: null,
  showResourceUpload: false,
  profilePhotoUpdated: false,
  isNavMenuOpen: false,
  message: {},
  isTourOpen: true,
  modalUserConfigState: false,
  sessionExpired: null,
  msgChangePassConfigUser: null,
  loading_data: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      };
    case UserActionTypes.SET_PROFILE_PHOTO_UPDATED:
      return {
        ...state,
        profilePhotoUpdated: true,
      };
    case UserActionTypes.SET_PROFILE_PHOTO_CHANGED:
      return {
        ...state,
        profilePhotoUpdated: false,
      };
    case UserActionTypes.SET_NAV_MENU_OPEN:
      return {
        ...state,
        isNavMenuOpen: payload,
      };
    case UserActionTypes.SHOW_MESSAGE:
      return {
        ...state,
        message: payload,
      };
    case UserActionTypes.HIDE_TOUR:
      return {
        ...state,
        isTourOpen: false,
      };
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        currentUser: null,
        isNavMenuOpen: false,
        tokenRecoveryPassword: null,
        sessionExpired: payload.sessionExpired,
        msgChangePassConfigUser: payload.reloadLogin,
      };
    case UserActionTypes.TOKEN_RECOVERY_PASSWORD:
      return {
        tokenRecoveryPassword: payload,
      };
    case UserActionTypes.CONFIG_USER_MODAL_STATE:
      return {
        ...state,
        modalUserConfigState: payload,
      };
    case UserActionTypes.GET_PROFILE_PHOTO:
      return {
        ...state,
        currentUser: { ...state.currentUser, profileImageUser: payload },
      };

    case UserActionTypes.CHANGE_STATUS_MESSAGE_EXPIRED:
      return {
        ...state,
        sessionExpired: null,
      };

    case UserActionTypes.RELOADING_MESSAGE:
      return {
        ...state,
        msgChangePassConfigUser: null,
      };

    case UserActionTypes.CHANGE_REDUX_NAME_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          name: payload.name,
          firstLastName: payload.firstLastName,
          secondLastName: payload.secondLastName,
        },
      };
    case UserActionTypes.ADD_CURRENT_USER_INFO:
      return {
        ...state,
        loading_data: false,
        currentUser: {
          ...state.currentUser,
          ...payload,
        },
      };
    case UserActionTypes.LOADING_USER_DATA:
      return {
        ...state,
        loading_data: true,
      };
    default:
      return state;
  }
};

export default userReducer;
