//Libs
import React from "react";
//Components
import Picker from "emoji-picker-react";
import { FacebookSelector } from "@charkour/react-reactions";
import Checkbox from "./SuperChat/Checkbox";
import { getHumanDate } from "Utils/Datetools";

//Styles
import styled from "styled-components";
import { Radio } from "antd";

export const MessageCard = ({ idx, message, currentUserId }) => {
  const { userId, createdAt, body, isQuestion, user } = message;
  const { name } = user;
  return (
    <div
      className={`text-message-chat-color rounded-lg p-3 mb-5 ${
        currentUserId === userId ? "bg-user-login-box" : "bg-user-msg-box"
      } ${isQuestion && "border-2 border-solid border-chat-question"}`}
      id={`message_${idx}`}
      key={idx}
    >
      <div>
        <span className="text-usname-color mb-0 font-bold">@{name}</span>
        <span className="text-date-color ml-1 text-xs">
          {getHumanDate(createdAt)}
        </span>
      </div>
      <p className="text-content-color">{body}</p>
    </div>
  );
};

const SendAnswersButton = styled.button``;
//TODO crear pollQuestions
//TODO crear pollReview

export const PollCard = ({
  key,
  poll,
  onAnswerPoll,
  checkAnswer,
  userVoteYet,
}) => {
  //Este de aca es lo que se encarga de enviar el answer
  const [value, setValue] = React.useState({});
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <div className="mb-5" key={key}>
      {userVoteYet ? (
        <div className="flex flex-col overflow-hidden rounded-xl relative mt-3 p-5">
          <div className="text-center text-card-msg-color ">
            <h4 className="ml-2">Gracias por su respuesta!</h4>
          </div>
        </div>
      ) : (
        <div className="flex flex-col overflow-hidden rounded-xl relative mt-3 p-5">
          <h4 className="text-center">{poll.question}</h4>

          {poll.isMultiAnswer ? (
            <Checkbox
              answerOptions={poll.options}
              onAnswerPoll={onAnswerPoll}
              checkAnswer={checkAnswer}
              isMultiAnswer={poll.isMultiAnswer}
            />
          ) : (
            poll.options.map((option, idx) => (
              <Radio.Group key={idx} onChange={onChange} value={value}>
                <Radio style={radioStyle} value={option} className="h5">
                  {option.name}
                </Radio>
              </Radio.Group>
            ))
          )}

          {poll.isMultiAnswer ? null : (
            <div style={{ marginTop: "20px" }} className="row">
              <div className="col-sm">
                <SendAnswersButton
                  onClick={() => {
                    onAnswerPoll(value, poll.isMultiAnswer, true); // true porque es local
                    checkAnswer();
                  }}
                  className="btn btn-success col-sm"
                >
                  Enviar
                </SendAnswersButton>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

//Emoticon Picker
// const Picker2 = new Picker();
// export const EmoticonPicker = styled(() => <Picker2 />)`
/* Write styles here... */
//`;

//Reaction Picker
export const ReactionPicker = styled(FacebookSelector)`
  width: 50px;
`;

export const PollButton = styled.button`
  /* Write styles here... */
`;

export const InputCheckBoxMessage = styled.input`
  cursor: pointer;
  color: white;
  font-size: large;
`;
export const EmoticonIcons = styled.div`
  cursor: pointer;
  font-size: x-large;
  position: relative;
  right: 45px;
  width: 50px;
`;

export const EmojiPicker = styled(Picker)`
  aside.emoji-picker-react {
    margin-top: 50%;
    background-color: black;
  }
`;
