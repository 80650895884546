import QuestionType from "./questions-type";

const INITIAL_STATE = {
  questionData: null,
  loading: false,
  code: null,
  appreciationMessage: null,
  result: null,
};

const questionReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case QuestionType.IS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case QuestionType.GET_QUESTION_LIST:
      return {
        ...state,
        questionData: {
          ...payload,
        },
      };
    case QuestionType.GET_C0DE:
      return {
        ...state,
        code: payload,
      };
    case QuestionType.ClEAR_ANSWER:
      const { clearAnswer } = payload;
      return {
        ...state,
        code: null,
        questionData: clearAnswer ? null : state.questionData,
        loading: false,
      };
    case QuestionType.APPRECIATION_MESSAGE:
      return {
        appreciationMessage: payload,
      };
    case QuestionType.GET_QUESTION_RESULT:
      return {
        ...state,
        result: payload,
      };
    default:
      return state;
  }
};

export default questionReducer;
