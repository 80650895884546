import React from "react";
//component
import { FiAlertCircle } from "react-icons/fi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import PropTypes from "prop-types";

const InformativeMsg = ({ msg, type }) => {
  return msg && type === "succeMsg" ? (
    <div
      className="py-1 text-left border-2 text-green-500 border-green-500 m-3
    text-opacity-100 bg-opacity-10 bg-green-600  rounded-lg"
    >
      <div className="flex">
        <AiOutlineCheckCircle className="w-6 h-6 mx-2 stroke-current" />
        <span className="items-start font-semibold">{msg}</span>
      </div>
    </div>
  ) : (
    <div
      className="py-1 text-left border-2 text-red-500 border-red-500 m-3
    text-opacity-100 bg-opacity-10 bg-red-600 rounded-lg"
    >
      <div className="flex ">
        <FiAlertCircle className="w-6 h-6 mx-2 stroke-current" />
        <span className="items-start font-semibold">{msg}</span>
      </div>
    </div>
  );
};

InformativeMsg.propTypes = {
  msg: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
export default InformativeMsg;
