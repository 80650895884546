import React, { useState } from "react";
//library
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AiOutlineCheckCircle } from "react-icons/ai";
//component
import { SpinnerButton } from "Components";
//service
import { addNewUserCode } from "Services/QuestionQuizService";
//Redux
import { connect } from "react-redux";
//select
import { selectedOrganizationId } from "Redux/Organization/organization-select";
import { selectCurrentUser } from "Redux/User/user-selectors";
const RegisterEmailPage = ({ organizationId, currentUser }) => {
  const [message, setErrorMessage] = useState({
    errorMessage: null,
    successMessage: null,
  });
  const [status, setStatus] = useState(null);

  const schema = yup.object({
    lastNames: yup.string().required("Los apellidos son requeridos."),
    names: yup.string().required("Los nombres son requeridos."),
    phoneNumber: yup.string().required("El número telefónico es requerido."),
    email: yup
      .string()
      .email("Tiene que ser un formato de correo valido.")
      .required("El correo es requerido."),
    reference: yup.string().required("La referencias son requeridas."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const [loading, setloading] = useState(false);

  const onSubmit = async (data) => {
    const { token } = currentUser;
    setloading(true);
    const state = await addNewUserCode(organizationId, token, data).then(
      (response) => response.status
    );
    setloading(false);
    setStatus(state);

    if (state !== 201) {
      setErrorMessage({
        errorMessage:
          "Ocurrió un problema al generar código, por favor inténtalo de nuevo. ",
      });
      setTimeout(() => {
        setErrorMessage({
          errorMessage: null,
          successMessage: null,
        });
      }, 5000);
      return;
    } else {
      setErrorMessage({
        successMessage: "El código fue creado con éxito y enviado al usuario.",
      });
      setTimeout(() => {
        setErrorMessage({
          errorMessage: null,
          successMessage: null,
        });
        setStatus(null);
      }, 5000);
    }

    reset();
  };

  return (
    <div
      className="w-screen min-h-screen bg-sign-in-background bg-cover
        bg-center flex justify-center md:pt-28 md:pb-14"
    >
      <div
        className="w-full min-h-full md:h-full md:w-vw-34 lg:w-vw-24 md:py-8 glass md:rounded-lg flex ;
        flex-col items-center justify-center md:justify-start px-1 py-4 md:px-10"
      >
        <span className="text-primary-label-input text-xl lg:text-4xl font-primary-font-family mb-3">
          Ingrese los siguientes datos
        </span>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="min-w-full px-3 lg:px-0 lg:w-8.5/12 flex flex-col justify-center text-center"
        >
          <div className="w-full flex flex-col lg:flex-row gap-2">
            <div className="flex flex-col w-full lg:w-1/2">
              <label className="text-primary-label-msg font-primary-font-family font-bold text-xl mb-2 text-left">
                Nombres
              </label>
              <input
                {...register("names")}
                className={`bg-primary-input-user-config input input-bordered border-2 ${
                  errors?.names && "input-error"
                }`}
              />
              {errors?.names && (
                <div className="py-1 text-left">
                  <span className="items-start text-primary-labelError font-semibold">
                    {errors?.names.message}
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full lg:w-1/2">
              <label className="text-primary-label-msg font-primary-font-family font-bold text-xl mb-2 text-left">
                Apellidos
              </label>
              <input
                {...register("lastNames")}
                className={`bg-primary-input-user-config input input-bordered border-2 ${
                  errors?.lastNames && "input-error"
                }`}
              />
              {errors?.lastNames && (
                <div className="py-1 text-left">
                  <span className="items-start text-primary-labelError font-semibold">
                    {errors?.lastNames.message}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-2 mt-2">
            <div className="flex flex-col w-full lg:w-1/2">
              <label className="text-primary-label-msg font-primary-font-family font-bold text-xl mb-2 text-left">
                Telefono celular
              </label>
              <input
                {...register("phoneNumber")}
                className={`bg-primary-input-user-config input input-bordered border-2 ${
                  errors?.phoneNumber && "input-error"
                }`}
              />
              {errors?.phoneNumber && (
                <div className="py-1 text-left">
                  <span className="items-start text-primary-labelError font-semibold">
                    {errors?.phoneNumber.message}
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full lg:w-1/2">
              <label className="text-primary-label-msg font-primary-font-family font-bold text-xl mb-2 text-left">
                email
              </label>
              <input
                {...register("email")}
                className={`bg-primary-input-user-config input input-bordered border-2 ${
                  errors?.email && "input-error"
                }`}
              />
              {errors?.email && (
                <div className="py-1 text-left">
                  <span className="items-start text-primary-labelError font-semibold">
                    {errors?.email.message}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-2 mt-2">
            <div className="flex flex-col w-full">
              <label className="text-primary-label-msg font-primary-font-family font-bold text-xl mb-2 text-left">
                Referencia
              </label>
              <input
                {...register("reference")}
                className={`bg-primary-input-user-config input input-bordered border-2 ${
                  errors?.reference && "input-error"
                }`}
              />
              {errors?.reference && (
                <div className="py-1 text-left">
                  <span className="items-start text-primary-labelError font-semibold">
                    {errors?.reference.message}
                  </span>
                </div>
              )}
            </div>
          </div>
          <button
            type="submit"
            style={{
              backgroundColor: "rgba(44, 168, 214, var(--tw-bg-opacity))",
            }}
            className={`btn bg-primary-button hover:bg-primary-hover-button mt-4`}
          >
            {loading && <SpinnerButton />}
            {loading ? "Creando" : "Agregar"}
          </button>

          {message.errorMessage && (
            <div className="alert alert-error">
              <div className="flex-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-6 h-6 mx-2 stroke-current"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                  ></path>
                </svg>
                <label>{message.errorMessage}</label>
              </div>
            </div>
          )}

          {status === 201 && (
            <div className="py-1 text-left alert alert-success">
              <div className="flex-1">
                <AiOutlineCheckCircle className="w-6 h-6 mx-2 stroke-current" />
                <span className="items-start font-semibold">
                  {message.successMessage}
                </span>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  organizationId: selectedOrganizationId(state),
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps, null)(RegisterEmailPage);
