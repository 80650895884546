
const getSubModuleComponent = (path) => {
	//Sub modulo inject in components
	switch (path) {

		default:
			return null;
	}
};
const injectSubComponent = (modules = []) => {
	return modules.map((module) => ({
		...module,
		component: getSubModuleComponent(module.subModule.path),
	}));
}
export default injectSubComponent;
