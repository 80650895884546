import AssigmentsType from "./Assigments-types";

export const setMyAssigment = (assigments) => ({
  type: AssigmentsType.SET_CURRENT_ASSIGMENTS,
  payload: assigments,
});

export const resetMyAssigment = () => ({
  type: AssigmentsType.RESET_MY_ASSIGMENTS,
});
