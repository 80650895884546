import styled, { css } from "styled-components";

const StyledMobileHeader = styled.div`
  width: 100vw;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 40;
  padding: 0 2.1vw;
  ${(props) =>
    window.scrollY === 0
      ? css`
          background-color: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.7) 10%,
            rgba(0, 0, 0, 0)
          );
        `
      : !props.scrollUp
      ? css`
          background-color: #000;
        `
      : props.scrollUp &&
        css`
          background-color: #000;
        `};
  transition: background-color 0.7s, opacity 0.3s;
`;
export default StyledMobileHeader;
