import typeCompany from "Redux/DynamicConfigCompany/dcc-type";

export const setCompanyData = (data) => ({
  type: typeCompany.SET_COMPANY_DATA,
  payload: data,
});
// set the component module
export const setModuleOrganization = (organizationSelected) => ({
  type: typeCompany.SET_ORGANIZATION_MODULE,
  payload: organizationSelected,
});

//the organizacion is selected
export const orgnizacionUnseleted = (modules) => ({
  type: typeCompany.ORGANIZATION_UNSELETED,
  payload: modules,
});
//Clear dcc
export const dccLogout = () => {
  return {
    type: typeCompany.DCC_CLEAR,
  };
};
