//Libs
import React, { Fragment } from "react";
import { uuid } from "uuidv4";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import ReactPlayer from "react-player";
import qs from "query-string";
import { SuperChat } from "Components/index";
import { createPollInDatabase } from "../../Services/VideoService";
//Actions
import {
  loadNewVideoLiveCurrently,
  ZoomIsOpen,
} from "Redux/Video/video-actions";
//Selectors
import {
  selectCurrentUser,
  selectPermissionCurrentUser,
} from "Redux/User/user-selectors";
import { selectRooms, selectIsRoomsFetching } from "Redux/Room/room-selectors";
import {
  //Video
  selectCurrentVideoId,
  selectCurrentVideoUrl,
  selectCurrentVideoStreamId,
  selectCurrentWithChat,
  //Messages
  selectMessages,
  selectMessagesLoaded,
  //Polls
  selectPolls,
  selectPollAnswers,
  selectCurrentVideoStreamServer,
  selectVideoSettings,
  selectZoomIsOpen,
} from "Redux/Video/video-selectors";
//Actions
import {
  //Messages
  addMessageToLocalList,
  sendMessageToDatabase,
  //Video
  setVideoProperties,
  resetVideoProperties,
  getVideoData,
  //Polls
  addPollToLocalList,
  addPollAnswerCounter,
  updatePollToLocalList,
} from "Redux/Video/video-actions";
import { getRoomData } from "Redux/Room/room-actions";
//Components
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ZoomRoom } from "Components";

//Config
import {
  ROUTES,
  WEBRTC_DELAY_LOOP_REQUEST,
  WEBRTC_BACKGROUND_IMAGE,
  WEBRTC_FINAL_IMAGE,
} from "Config/config";
//Styles
import "./WatchVideoRoomPage.css";
//Utils
import { WebRTCAdaptor } from "Utils/WebRTCAdapator";
//Service
import MixPanel from "Services/MixPanelService";
//Service answer Poll
import { setPollAnswerDataBase } from "Services/VideoService";
import { selectedOrganizationId } from "Redux/Organization/organization-select";
import { currentUserLeaveZoomMeet } from "Components/ZoomRoom/ZoomRoom";

class WatchVideoRoomPage extends React.Component {
  interval;

  constructor() {
    super();
    this.state = {
      webRTC: null,
      waitingVideo: true,
      dataChannelIsOpen: false,
      time: Date.now(),
      noStreamExist: true,
      newMessage: "",
      newMessageIsQuestion: false,
      scrollToBottom: false,
      firstLoad: true,
      showNewMessagesButton: false,
      intervalCleared: false,
      isLastMessageShown: true,
      messagesEndRef: null,
      ref: "",
      getRoomIdWithPoll: null,
      reactions: [],
    };
    this.sendNewReaction = this.sendNewReaction.bind(this);
    this.stopShowingReaction = this.stopShowingReaction.bind(this);
  }

  componentDidMount() {
    const {
      currentUser,
      match,
      history,
      location,
      resetVideoProperties,
      streamWss,
    } = this.props;
    //Get video data
    const paramRoomId = parseInt(match.params.roomId);
    const resourceId = parseInt(match.params.videoId);
    this.setState({
      getRoomIdWithPoll: paramRoomId,
    });
    //ROOM
    if (location.pathname.includes(ROUTES.ROOM)) {
      if (!isNaN(paramRoomId) && !isNaN(resourceId)) {
        this.props
          .getRoomData(currentUser.token, paramRoomId, {
            organizationId: this.props.organizationId,
          })
          .then((success) => {
            if (!success) {
              resetVideoProperties();
              history.push(ROUTES.HOME);
              return;
            }
          });
      } else {
        resetVideoProperties();
        history.push(ROUTES.HOME);
        return;
      }

      //Initialize WebRTC Adapter
      if (streamWss) {
        this.initializeWebRTCAdaptor();
      }
      this.interval = setInterval(
        () => this.setState({ time: Date.now() }),
        WEBRTC_DELAY_LOOP_REQUEST
      );
    }
    //VIDEO
    else if (location.pathname.includes(ROUTES.VIDEO)) {
      if (match.params.videoId !== "welcome") {
        if (!isNaN(resourceId)) {
          const ref = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
          }).ref
            ? qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
                .ref
            : "";
          this.setState({
            ref,
          });
          this.props
            .getVideoData(
              currentUser.token,
              resourceId,
              this.props.organizationId
            )
            .then((success) => {
              if (!success) {
                resetVideoProperties();
                history.push(ROUTES.HOME);
                return;
              }
            });
        } else {
          resetVideoProperties();
          history.push(ROUTES.HOME);
          return;
        }
      }
    }
  }

  componentWillUnmount() {
    const { webRTC } = this.state;
    if (webRTC) {
      try {
        webRTC.closeWebSocket();
      } catch (error) {
        console.log("Error closing the socket");
      }
    }
  }

  componentDidUpdate() {
    const { noStreamExist, webRTC, scrollToBottom, intervalCleared } =
      this.state;

    if (scrollToBottom) {
      this.scrollToBottom();
      this.setState({
        scrollToBottom: false,
      });
    }
    if (noStreamExist) {
      if (webRTC) {
        webRTC.getStreamInfo(this.props.streamId);
      }
    } else {
      if (!intervalCleared) {
        clearInterval(this.interval);
      }
    }
  }

  startVideoPlaying = () => {
    this.setState({
      waitingVideo: false,
    });
  };

  webRTCCallback = (info, description) => {
    //SOCKETS
    const { webRTC } = this.state;
    const { streamId } = this.props;

    if (info === "initialized") {
      webRTC.getStreamInfo(streamId);
    } else if (info === "streamInformation") {
      webRTC.play(description.streamId, streamId, "roomName");
    } else if (info === "play_started") {
      this.setState({
        waitingVideo: false,
        noStreamExist: false,
      });
      this.video && this.video.play();
    } else if (info === "play_finished") {
      setTimeout(function () {
        webRTC.getStreamInfo(streamId);
      }, 3000);
    } else if (info === "closed") {
      if (typeof description != "undefined") {
        console.log("Connecton closed: " + JSON.stringify(description));
      }
    } else if (info === "bitrateMeasurement") {
      if (
        description.audioBitrate + description.videoBitrate >
        description.targetBitrate
      ) {
        console.log(
          "Usted no cuenta con una red capaz de reproducir este video"
        );
      }
    }
    if (info === "data_channel_opened") {
      this.setState({
        dataChannelIsOpen: true,
      });

      setInterval(() => {
        MixPanel.track(MixPanel.TYPES.EVENT_TOTAL_USER_LIVE, {
          user: this.props.currentUser.id,
          room: this.props.currentVideoId,
          orgName: this.props.orgName,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          streamId: this.props.streamId,
          streamWss: this.props.streamWss,
          roomName: this.props.roomNameVideo,
        });
      }, 30000);
    } else if (info === "data_received") {
      const data = description.event.data;
      const messageObj = JSON.parse(data);
      if (messageObj.type === "videoUpdate") {
        this.props.loadNewVideoLiveCurrently(messageObj.videoId);
        //Ya no se mandará a traer la data del vídeo
        //Get video data
        /*this.props.history.push(
          `${ROUTES.ROOM}/${this.props.match.params.roomId}/${messageObj.videoId}`
        );*/
      } else if (messageObj.type === "reaction") {
        this.sendNewReaction(messageObj);
      } else if (messageObj.type === "poll") {
        this.sendNewPoll(messageObj);
      } else if (messageObj.type === "pollAnswer") {
        this.sendPollAnswer(messageObj);
      } else if (messageObj.type === "pollDelete") {
        this.pollDelete(messageObj);
      } else if (messageObj.type === "message") {
        this.sendNewMessage(messageObj);
      }
    } else if (info === "data_channel_error") {
      console.log("HANDLE ERROR HERE: ", description);
    } else if (info === "data_channel_closed") {
      this.setState({
        dataChannelIsOpen: false,
      });
    }
  };

  initializeWebRTCAdaptor = () => {
    const { streamWss } = this.props;
    const webRTC = new WebRTCAdaptor({
      websocket_url: streamWss,
      mediaConstraints: {
        video: true,
        audio: true,
      },
      peerconnection_config: null,
      sdp_constraints: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      },
      remoteVideoId: "remoteVideo",
      isPlayMode: true,
      callback: this.webRTCCallback,
      callbackError: function (error, message) {
        console.log("Some message in error: ", message);
        console.log("error callback: " + JSON.stringify(error));
      },
    });
    this.setState({
      webRTC,
    });
  };

  sendNewMessage(message, local) {
    const { webRTC, dataChannelIsOpen } = this.state;
    const { currentVideoId } = this.props;
    if (dataChannelIsOpen) {
      if (local) {
        webRTC.sendData(this.props.streamId, message);
      }
    }
    if (message.videoId === currentVideoId) {
      this.saveMessageInState(message, local);
    }
  }
  sendNewReaction(reaction, local) {
    const { webRTC, dataChannelIsOpen, reactions } = this.state;
    const { currentUser, currentVideoId, roomNameVideo, orgName } = this.props;

    if (local) {
      MixPanel.track(MixPanel.TYPES.REACTION_WITH_CHAT_ROOM, {
        user: currentUser.id,
        orgName: orgName,
        room: currentVideoId,
        roomNameVideo: roomNameVideo,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        streamId: this.props.streamId,
        streamWss: this.props.streamWss,
        raction: reaction,
      });

      const reactionId = uuid();
      if (dataChannelIsOpen) {
        webRTC.sendData(this.props.streamId, {
          idKey: reactionId,
          type: "reaction",
          reaction,
        });
      }
      const reactionsClone = _.clone(reactions);
      reactionsClone.push({
        reaction,
        id: reactionId,
        show: true,
      });
      this.setState({
        reactions: reactionsClone,
      });
    } else {
      const reactionsClone = _.clone(reactions);
      const reactionId = reaction.idKey;
      reactionsClone.push({
        reaction: reaction.reaction,
        id: reactionId,
        show: true,
      });
      this.setState({
        reactions: reactionsClone,
      });
    }
  }

  stopShowingReaction(id) {
    const { reactions } = this.state;
    const reactionsClone = reactions.map((r) => {
      const reaction = { ...r };
      if (reaction.id === id) {
        reaction.show = false;
      }
      return reaction;
    });
    this.setState({
      reactions: reactionsClone,
    });
  }

  sendNewPoll(poll, local) {
    const { webRTC, dataChannelIsOpen } = this.state;
    const { currentUser } = this.props;
    const timer = null;

    const options = poll.optionValue || [];
    const newArrayOpcion =
      Array.isArray(options) && options.map(({ value }) => value);

    if (local) {
      if (!isNaN(poll.roomId)) {
        createPollInDatabase(
          currentUser.token,
          poll.multiAnswer,
          poll.question,
          poll.roomId,
          newArrayOpcion,
          this.props.organizationId,
          timer
        ).then((_poll) => {
          if (_poll) {
            this.props.addPollToLocalList({ ..._poll, idKey: uuid() });

            if (local && dataChannelIsOpen) {
              const PollWebRTc = {
                ..._poll,
                idKey: uuid(),
                type: "poll",
              };
              webRTC.sendData(this.props.streamId, PollWebRTc);
            }
          }
        });
      }
    } else {
      delete poll.type;
      this.props.addPollToLocalList({ poll, idKey: uuid() });
    }
  }

  sendPollAnswer = (poll, isMultiAnswer, local) => {
    const { currentUser, organizationId, currentVideoId } = this.props;
    const { webRTC, dataChannelIsOpen } = this.state;

    if (poll) {
      if (isMultiAnswer) {
        poll.forEach((poll) => {
          if (local) {
            setPollAnswerDataBase(currentUser.token, {
              organizationId,
              currentVideoId,
              pollId: poll.pollId,
              PollOptionId: poll.id,
            });
            const pollWebRTC = {
              ...poll,
              idKey: uuid(),
              type: "pollAnswer",
              organizationId,
              currentVideoId,
            };

            if (dataChannelIsOpen) {
              webRTC.sendData(this.props.streamId, pollWebRTC);
            }
          }
          this.props.insertPollAnswerCounter({
            pollId: poll.pollId,
            answer: poll.name,
            idKey: uuid(),
          });
        });
      } else {
        const webUniqueAnswerWebSocket = {
          ...poll,
          type: "pollAnswer",
          idKey: uuid(),
          organizationId,
          currentVideoId,
        };

        if (local) {
          if (dataChannelIsOpen) {
            webRTC.sendData(this.props.streamId, webUniqueAnswerWebSocket);
          }
          setPollAnswerDataBase(currentUser.token, {
            organizationId,
            currentVideoId,
            pollId: poll.pollId,
            PollOptionId: poll.id,
          });
        }

        this.props.insertPollAnswerCounter({
          pollId: poll.pollId,
          answer: poll.name,
          idKey: uuid(),
        });
      }
    }
  };
  pollDelete = (poll, local) => {
    const { currentUser, organizationId, currentVideoId } = this.props;
    const { id } = poll;
    const { webRTC, dataChannelIsOpen } = this.state;

    if (local) {
      const webStatePoll = {
        pollId: id,
        idKey: uuid(),
        organizationId,
        currentVideoId,
        type: "pollDelete",
      };

      if (dataChannelIsOpen) {
        webRTC.sendData(this.props.streamId, webStatePoll);
      }
      this.props.updatePollToLocalList({
        token: currentUser.token,
        pollId: id,
        organizationId,
        currentVideoId,
        local,
      });
    } else {
      this.props.updatePollToLocalList();
    }
  };

  saveMessageInState = (message, local) => {
    const { isLastMessageShown } = this.state;
    this.props.addMessageToLocalList(message);

    if (local) {
      this.setState({
        scrollToBottom: true,
      });
    } else {
      if (isLastMessageShown) {
        this.setState({
          scrollToBottom: true,
        });
      } else {
        this.setState({
          showNewMessagesButton: true,
        });
      }
    }
  };

  setMessageEndRef = (el) => {
    const { messagesEndRef } = this.state;
    if (!messagesEndRef) {
      this.setState({
        messagesEndRef: el,
      });
    }
  };

  scrollToBottom = (firstLoad) => {
    const { messagesEndRef } = this.state;
    if (messagesEndRef) {
      messagesEndRef.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      this.setState({
        showNewMessagesButton: false,
      });
    }
    if (firstLoad) {
      this.setState({
        firstLoad: false,
      });
    }
  };

  handleScroll = (event) => {
    const { showNewMessagesButton } = this.state;
    const originalHeight = event.target.clientHeight;
    const currentScroll = event.target.scrollTop;
    const maxHeight = event.target.scrollHeight;
    this.setState({
      isLastMessageShown:
        maxHeight - 40 - (originalHeight + currentScroll) <= 0,
      showNewMessagesButton:
        showNewMessagesButton &&
        maxHeight - (originalHeight + currentScroll) > 0,
    });
  };

  render() {
    const {
      waitingVideo,
      newMessage,
      newMessageIsQuestion,
      showNewMessagesButton,
      firstLoad,
      dataChannelIsOpen,
      ref,
      //Video Control
      fullScreen = false,
      reactions,
    } = this.state;

    const {
      mobileView,
      messages,
      polls,
      currentUser,
      currentVideoId,
      currentVideoUrl,
      currentWithChat,
      resetVideoProperties,
      location,
      streamId,
    } = this.props;

    const noVideo =
      location.pathname.includes(ROUTES.VIDEO) && !currentVideoUrl;
    return (
      <Fragment>
        {this.props?.videoSettings?.source.includes("zoomRoomMeet") &&
        this.props.selectZoomIsOpen ? (
          <div
            className="movie-item-page__back"
            onClick={() => {
              currentUserLeaveZoomMeet();
              const { zoomStart } = this.props;
              zoomStart(false);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        ) : (
          <Link
            to={`${ROUTES.HOME}${ref ? `?ref=${ref}` : ""}`}
            onClick={() => {
              resetVideoProperties();
              this.video && this.video.pause();
            }}
          >
            <div className="movie-item-page__back">
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
          </Link>
        )}
        {noVideo ? (
          <div
            style={{
              display: "flex",
              height: "100vh",
              alignItems: "center",
            }}
          >
            <img
              alt="No video background"
              style={{ width: "100vw" }}
              src={WEBRTC_FINAL_IMAGE}
            />
          </div>
        ) : (
          <div
            style={{
              height: "100vh",
              display: "grid",
              gridTemplateRows:
                mobileView &&
                location.pathname.includes(ROUTES.ROOM) &&
                "56.25vw calc(100vh - 56.25vw)",
              gridTemplateColumns: `${
                mobileView
                  ? `repeat(1, 100%)`
                  : `repeat(${currentWithChat ? 2 : 1}, ${
                      currentWithChat ? "70% 30%" : "100%"
                    })`
              }`,
            }}
          >
            <div
              style={{
                display: "flex",
                height: mobileView ? "100%" : "100vh",
                width: mobileView ? "100vw" : "100%",
                alignItems: "center",
              }}
            >
              {location.pathname.includes(ROUTES.ROOM) && streamId ? (
                <React.Fragment>
                  {streamId.indexOf("youtube") === -1 ? (
                    streamId.indexOf("vimeo") > -1 ? ( //THIS MEANS IS FROM VIMEO
                      <iframe
                        title="vimeo transmission"
                        src={streamId}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{ width: "100%", height: "100%" }}
                      ></iframe>
                    ) : streamId.indexOf("m3u8") > -1 ? ( //THIS MEANS IS FROM VIMEO
                      <ReactPlayer
                        playing={true} //Autoplay
                        width="100%"
                        height="100vh"
                        loop
                        playsInline
                        stopOnUnmount={false}
                        url={streamId}
                        controls
                        onReady={(player) => {
                          const hls = player.getInternalPlayer("hls");
                          hls.startLevel = hls.levels.length - 1;
                        }}
                      />
                    ) : streamId.indexOf("zoomRoomMeet", "https://") === -1 ? ( //this is a WebRTCStreaming
                      <video
                        muted
                        ref={(el) => {
                          this.video = el;
                        }}
                        poster={
                          waitingVideo
                            ? WEBRTC_BACKGROUND_IMAGE
                            : WEBRTC_FINAL_IMAGE
                        }
                        className={
                          currentWithChat
                            ? "movie-item-page__video"
                            : "movie-item-page__videoWithoutChat"
                        }
                        id="remoteVideo"
                        autoPlay
                        controls
                        playsInline
                      />
                    ) : this.props?.videoSettings?.source.includes(
                        "zoomRoomMeet"
                      ) ? ( // this is zoom meet
                      <>
                        {this.props?.videoSettings?.meetingNumber && (
                          <ZoomRoom
                            settings={this.props.videoSettings}
                            currentUser={this.props.currentUser}
                            getLastUserMeet={this.props.getLastUserMeet}
                            lastZoomList={this.props.lastZoomList}
                            zoomStart={this.props.zoomStart}
                          />
                        )}
                      </>
                    ) : (
                      //THIS MEANS IT IS HLS USING ANT MEDIA
                      <iframe
                        title="hls transmission"
                        id="hls-player"
                        frameBorder="0"
                        allowFullScreen={true}
                        seamless="seamless"
                        style={{ width: "100%", height: "100%" }}
                        src={streamId}
                      ></iframe>
                    )
                  ) : (
                    //THIS MEANS IS A YOUTUBE VIDEO
                    <iframe
                      title="youtube transmission"
                      style={{ width: "100%", height: "100%" }}
                      src={streamId}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </React.Fragment>
              ) : (
                <Fragment>
                  <ReactPlayer
                    playing={true} //Autoplay
                    width="100%"
                    height="100vh"
                    playsinline
                    stopOnUnmount={false}
                    url={currentVideoUrl}
                    controls
                    onReady={(player) => {
                      const hls = player.getInternalPlayer("hls");
                      hls.startLevel = hls.levels.length - 1;
                    }}
                  />
                  {/*<div>
                    <button
                      onClick={() => handleFullScreen()}
                      className="w-100 btn btn-success fullScreenIcon"
                    >
                      {fullScreen ? (
                        <div>
                          MINIMIZAR PANTALLA <ShrinkOutlined />
                        </div>
                      ) : (
                        <div>
                          VER EN PANTALLA COMPLETA <ArrowsAltOutlined />
                        </div>
                      )}
                    </button>
                      </div>*/}
                </Fragment>
              )}
            </div>

            {currentWithChat && (
              <div>
                {streamId &&
                streamId.indexOf("youtube") === -1 &&
                streamId.indexOf("vimeo") === -1 &&
                streamId.indexOf("m3u8") === -1 &&
                streamId.indexOf("https://") === -1 ? (
                  <SuperChat
                    onClickNewMessagesButton={this.scrollToBottom}
                    showNewMessagesButton={showNewMessagesButton}
                    fullScreen={fullScreen}
                    newMessageIsQuestion={newMessageIsQuestion}
                    mobileView={mobileView}
                    messages={messages}
                    polls={polls}
                    roomId={this.state.getRoomIdWithPoll}
                    pollAnswers={this.props.pollAnswers}
                    dataChannelIsOpen={dataChannelIsOpen}
                    userId={currentUser.id}
                    permissions={this.props.permissions}
                    sendNewReaction={this.sendNewReaction}
                    reactions={reactions}
                    stopShowingReaction={this.stopShowingReaction}
                    onChangeIsQuestion={(isChecked) => {
                      this.setState({
                        newMessageIsQuestion: isChecked,
                      });
                    }}
                    onMessageInputEnterKeyPressed={(evt) => {
                      const payload = {
                        idKey: uuid(),
                        videoId: currentVideoId,
                        userId: currentUser.id,
                        user: {
                          name: `${currentUser.name}`,
                        },
                        body: evt.target.value,
                        isQuestion: newMessageIsQuestion,
                        currentVideoSecond: 0, //TODO: Se debe establecer el segundo exacto por donde iba el video
                        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                        type: "message",
                      };
                      const { orgName, roomNameVideo } = this.props;
                      if (newMessageIsQuestion) {
                        MixPanel.track(MixPanel.TYPES.CREATE_MESSAGE_QUESTION, {
                          message: evt.target.value,
                          orgName: orgName,
                          roomNameVideo: roomNameVideo,
                        });
                      }
                      //Save to Database
                      this.props.sendMessageToDatabase(currentUser.token, {
                        idKey: uuid(),
                        organizationId: this.props.organizationId,
                        resourceId: currentVideoId,
                        messageBody: evt.target.value,
                        currentVideoSecond: 0,
                        isQuestion: newMessageIsQuestion,
                      });
                      //Send to WebSockets
                      this.sendNewMessage(payload, true);
                      this.setState({
                        newMessageIsQuestion: false,
                      });
                    }}
                    onMessageInputChange={(evt) => {
                      const value = evt.target.value;
                      if (value.includes("?")) {
                        this.setState({
                          newMessageIsQuestion: true,
                        });
                      } else if (
                        newMessage.includes("?") &&
                        !value.includes("?")
                      ) {
                        this.setState({
                          newMessageIsQuestion: false,
                        });
                      }
                      this.setState({ newMessage: value });
                    }}
                    onCreatePoll={(payload) => {
                      //Send to WebSockets
                      this.sendNewPoll(payload, true);
                    }}
                    closeLocal={this.pollDelete}
                    onAnswerPoll={this.sendPollAnswer}
                    eventIsStarted={dataChannelIsOpen}
                    currentUserId={currentUser.id}
                    handleScroll={this.handleScroll}
                    setMessageEndRef={this.setMessageEndRef}
                    scrollToBottom={this.scrollToBottom}
                    firstLoad={firstLoad}
                  />
                ) : (
                  //ELSE WE MUST USE ARENA IM CHAT (TODO: MAKE IT CONFIGURABLE)
                  <iframe
                    title="arena chat"
                    src="https://go.arena.im/embed/chat/mastica-y-desbloquea/07fj"
                    style={{ height: "100%", width: "100%", border: "none" }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  //Home
  currentUser: selectCurrentUser(state),
  permissions: selectPermissionCurrentUser(state),
  //Rooms
  rooms: selectRooms(state),
  isFetching: selectIsRoomsFetching(state),
  videoSettings: selectVideoSettings(state),

  //Video
  currentVideoId: selectCurrentVideoId(state),
  currentVideoUrl: selectCurrentVideoUrl(state),
  streamId: selectCurrentVideoStreamId(state),
  streamWss: selectCurrentVideoStreamServer(state),
  currentWithChat: selectCurrentWithChat(state),
  //Messages
  messages: selectMessages(state),
  messagesLoaded: selectMessagesLoaded(state),
  //Polls
  polls: selectPolls(state),
  pollAnswers: selectPollAnswers(state),
  //organizationId
  organizationId: selectedOrganizationId(state),
  selectZoomIsOpen: selectZoomIsOpen(state),
});

const mapDispatchToProps = (dispatch) => ({
  //Video
  getRoomData: (token, roomId, whereOpts) =>
    dispatch(getRoomData(token, roomId, whereOpts)),
  getVideoData: (token, resourceId, organizationId) =>
    dispatch(getVideoData(token, resourceId, organizationId)),
  setVideoProperties: (props) => dispatch(setVideoProperties(props)),
  resetVideoProperties: () => dispatch(resetVideoProperties()),
  loadNewVideoLiveCurrently: (videoId) =>
    dispatch(loadNewVideoLiveCurrently(videoId)),
  //Message
  addMessageToLocalList: (newMessages) =>
    dispatch(addMessageToLocalList(newMessages)),
  sendMessageToDatabase: (token, idKey, whereOpts) =>
    dispatch(sendMessageToDatabase(token, idKey, whereOpts)),
  //polls
  addPollToLocalList: (whereOps) => dispatch(addPollToLocalList(whereOps)),
  insertPollAnswerCounter: (payload) => dispatch(addPollAnswerCounter(payload)),
  updatePollToLocalList: (payload) => dispatch(updatePollToLocalList(payload)),
  zoomStart: (payload) => dispatch(ZoomIsOpen(payload)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WatchVideoRoomPage);
