import React from "react";
import { Typography } from "antd";
import "./CopyRight.css";

const PrivacyPolicy = () => {
  const { Title } = Typography;

  return (
    <div>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={2}>
        Política de Privacidad
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Esta página web (en adelante “Página“) es brindada por Bayer S.A.(en
        adelante “nosotros”). Para obtener más información sobre el proveedor de
        la página web, consulte nuestro pie de imprenta.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={5}>
        A. Manejo de Datos Personales
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        A continuación, queremos proporcionarle información sobre cómo manejamos
        sus datos personales cuando utiliza nuestra página. A menos que se
        indique lo contrario en los siguientes capítulos, la base legal para el
        manejo de sus datos personales resulta del hecho de que dicho manejo es
        necesario para poner a su disposición las funcionalidades de la página
        que usted solicitó.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={3}>
        I. Utilización de la página
      </Title>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        1. Acceso a nuestra página
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Cuando se ingresa a nuestra página, su navegador transferirá ciertos
        datos a nuestro servidor web. Esto se hace por razones técnicas y se
        requiere para poner a su disposición la información solicitada. Para
        facilitar su acceso la página, se recopilan, almacenan y utilizan
        brevemente los siguientes datos:
      </p>
      <div className="two-paragraph">
        <p
          className="color-paragraph"
          style={{ userSelect: "none", fontWeight: "700" }}
        >
          <ul style={{ listStyle: "none" }}>
            <li>Dirección IP</li>
            <li>Fecha y hora de acceso</li>
            <li>
              Diferencia de zona horaria a la hora del meridiano de Greenwich
              (GMT)
            </li>
            <li>Contenido de la solicitud (sitio específico)</li>
          </ul>
        </p>
        <p
          className="color-paragraph"
          style={{ userSelect: "none", fontWeight: "700" }}
        >
          <ul style={{ listStyle: "none" }}>
            <li>Dirección IP</li>
            <li>Fecha y hora de acceso</li>
            <li>
              Diferencia de zona horaria a la hora del meridiano de Greenwich
              (GMT)
            </li>
            <li>Contenido de la solicitud (sitio específico)</li>
          </ul>
        </p>
      </div>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Al mismo tiempo, para proteger nuestros intereses legítimos,
        almacenaremos dichos datos durante un período de tiempo limitado a fin
        de poder iniciar un seguimiento de los datos personales en caso de que
        haya un acceso o intento de acceso no autorizado a nuestros servidores.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        2. Configuración de cookies
      </Title>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={5}>
        a) ¿Qué son cookies?
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Nuestra página utiliza los llamados “cookies”. Las cookies son pequeños
        archivos de texto almacenados en la memoria de su computador a través de
        su navegador. Almacenan cierta información (por ejemplo, su idioma de
        preferencia o la configuración de la página) que su navegador puede
        (según la duración de la cookie) retransmitirnos en su próxima visita a
        nuestra página.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={5}>
        b) ¿Qué cookies utilizamos?
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Diferenciamos entre dos categorías de cookies: (1) cookies funcionales,
        sin las cuales se reduciría la funcionalidad de nuestra página, y (2)
        cookies opcionales, utilizadas para análisis de páginas web y propósitos
        de marketing. La siguiente tabla contiene una descripción detallada de
        las cookies opcionales que utilizamos:
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={5}>
        Cookies requeridas para fines técnicos
      </Title>
      <table style={{ width: "100%" }}>
        <tr style={{ borderBottom: "#633d8c solid 1px" }}>
          <th>
            <Title style={{ color: "#633d8c", userSelect: "none" }} level={5}>
              Cookie(s)
            </Title>
          </th>
          <th>
            <Title style={{ color: "#633d8c", userSelect: "none" }} level={5}>
              Vigencia
            </Title>
          </th>
          <th>
            <Title style={{ color: "#633d8c", userSelect: "none" }} level={5}>
              Propósito y contenido
            </Title>
          </th>
        </tr>
        <tr style={{ borderBottom: "#633d8c solid 1px" }}>
          <td className="color-paragraph">PHPSESSID</td>
          <td className="color-paragraph" style={{ width: "30%" }}>
            Duración de la sesión: la cookie se elimina cuando se cierra el
            navegador.
          </td>
          <td className="color-paragraph">
            La identificación anónima de una sesión (utilizando el reproductor
            de video MI24) solo con fines técnicos. No se recopilan datos
            personales
          </td>
        </tr>
        <tr style={{ borderBottom: "#633d8c solid 1px" }}>
          <td className="color-paragraph">UniqueID</td>
          <td className="color-paragraph">6 meses</td>
          <td className="color-paragraph">
            La identificación anónima de la duración de la visita para el
            análisis estadístico con el fin de optimizar el sitio web. No se
            recogen datos personales
          </td>
        </tr>
        <tr style={{ borderBottom: "#633d8c solid 1px" }}>
          <td className="color-paragraph">WHGACE80</td>
          <td className="color-paragraph">1 día</td>
          <td className="color-paragraph">
            Cookie de balanceo de carga para garantizar el comportamiento
            correcto del sitio web
          </td>
        </tr>
        <tr style={{ borderBottom: "#633d8c solid 1px" }}>
          <td className="color-paragraph">WHGCOOKIECONSENT</td>
          <td className="color-paragraph">6 meses</td>
          <td className="color-paragraph">
            Recordar decisión sobre uso de Cookies opcionales Guarda su decisión
            de aceptar o rechazar el uso de Cookies opcionales. No se reúnen
            datos personales en este Cookie.
          </td>
        </tr>
        <tr style={{ borderBottom: "#633d8c solid 1px" }}>
          <td className="color-paragraph">Youtube</td>
          <td className="color-paragraph"></td>

          <td className="color-paragraph">
            Incorporamos videos de nuestros canales de YouTube utilizando el
            modo de privacidad de YouTube. Este modo puede configurar cookies en
            tu computadora una vez que hagas clic en el reproductor de video de
            YouTube, pero YouTube no almacenará información de cookies
            personalmente identificable para reproducciones de vídeos
            incrustados usando el modo de privacidad. Más información en la
            página de información sobre vídeos de incrustación de YouTube.
          </td>
        </tr>
      </table>
        <p className="color-paragraph" style={{ userSelect: "none", fontWeight: "700" }}>
        La configuración de cookies opcional se puede ver y cambiar en la
        Política de privacidad global
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={5}>
        c) Sujeto a su consentimiento
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Sólo utilizamos cookies opcionales si hemos obtenido su consentimiento
        con anterioridad. Tras su primer acceso a nuestra página, aparecerá un
        banner que le pedirá que nos dé su consentimiento para la configuración
        de cookies opcionales. Si otorga su consentimiento, colocaremos una
        cookie en su computador y el banner no aparecerá de nuevo mientras la
        cookie esté activa. Después del vencimiento de la duración de la cookie,
        o si la elimina de manera active, el banner volverá a aparecer en su
        próxima visita a nuestra página y le pedirá nuevamente su consentimiento
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={5}>
        d) ¿Cómo evitar la configuración de cookies?
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Por supuesto, puede usar nuestra página sin que se establezcan cookies.
        En su navegador, puede configurar o desactivar completamente el uso de
        cookies en cualquier momento. Sin embargo, esto puede conducir a una
        restricción de las funciones o tener eventos adversos sobre la facilidad
        de uso de nuestra página. En cualquier momento puede objetar la
        configuración de cookies opcionales, utilizando la opción de objeción
        respectiva indicada en la tabla anterior.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        3. Análisis de la página
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Nuestra página utiliza Google Analytics, un servicio de análisis web de
        Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, Estados
        Unidos (“Google”). Google analizará su uso de nuestra página web en
        nuestro nombre. Para ello, utilizamos, entre otros, los Cookies
        descritos con mayor detalle en la tabla anterior. La información
        recopilada por Google en relación con el uso de nuestra página (por
        ejemplo, la URL de referencia, las páginas web que visita, el tipo de
        navegador, la configuración de idioma, el sistema operativo y la
        resolución de pantalla) se transmitirán a un servidor de Google en los
        Estados Unidos, donde será almacenado y analizado. Los respectivos
        resultados se pondrán a nuestra disposición en forma anónima. En este
        proceso, sus datos de uso no se conectarán a su dirección IP completa.
        Hemos activado en nuestra página la función de anonimato IP ofrecida por
        Google, de modo que los últimos 8 dígitos (tipo IPv4) o los últimos 80
        bits (tipo IPv6) de su dirección IP se eliminan.
      </p>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Usted puede retirar su consentimiento para el uso de análisis web en
        cualquier momento, ya sea descargando e instalando el complemento Google
        Browser Plugin o administrando sus consentimientos en la tabla anterior,
        en cuyo caso se colocará una cookie de exclusión. Ambas opciones
        evitarán el uso del análisis web siempre y cuando utilice el navegador
        en el que instaló el complemento o si no elimina la cookie de exclusión.
      </p>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Para obtener más información sobre Google Analytics consulte las
        Condiciones de servicio de Google Analytics, los Principios de seguridad
        y privacidad de Google Analytics, y la Política de Privacidad de Google.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        4. Análisis de estadísticas web
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        En nuestro sitio web utilizamos una herramienta de análisis de
        estadísticas web. El objetivo principal del análisis de estadísticas web
        es conocer las funcionalidades más utilizadas para mejorar nuestro sitio
        web, por ejemplo, cuántos usuarios registrados por país están utilizando
        los diferentes servicios funcionales de “Mis Pedidos” (historial de
        pedidos, copia de facturas, seguimiento de entrega, entrada de pedidos).
        Para analizar el uso del sitio web, procesamos su ID de usuario y las
        funcionalidades que ha utilizado durante su visita a nuestro sitio web.
        Almacenaremos sus datos personales y los resultados del análisis durante
        6 meses.
      </p>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Puede retirar su consentimiento al uso del análisis de estadísticas web
        en cualquier momento administrando sus consentimientos en la tabla
        anterior, en cuyo caso se colocará una cookie de exclusión.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        5. Uso de formularios de contacto
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Puede contactarnos directamente a través de los formularios de contacto
        disponibles en nuestra página. Recopilamos, procesamos y utilizamos la
        información proporcionada por usted a través de los formularios de
        contacto exclusivamente para el procesamiento de su solicitud
        específica.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        6. Servicios Externos o contenido de terceros en nuestra página
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Incluimos servicios y/o contenido de terceros en nuestra página. Cuando
        utiliza dichos servicios de terceros o cuando se muestra contenido de
        terceros, los datos de comunicación se intercambian entre usted y el
        proveedor respectivo por razones técnicas.
        <p></p>
        El proveedor respectivo de los servicios o del contenido también puede
        procesar sus datos para fines adicionales propios. A nuestro saber y
        entender, configuramos los servicios y e l contenido de los proveedores
        que procesan datos para fines propios, de forma tal que cualquier
        comunicación para fines distintos de presentar sus servicios o contenido
        en nuestra página está bloqueada, o la comunicación sólo se lleva a cabo
        una vez que haya optado activamente por usar el servicio respectivo. Sin
        embargo, dado que no tenemos control sobre los datos recopilados y
        procesados por terceros, no estamos en condiciones de proporcionar
        información vinculante sobre el alcance y el propósito de dicho
        procesamiento de sus datos.
      </p>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Para obtener más información sobre el alcance y el propósito de dicha
        recopilación y procesamiento de sus datos, consulte las declaraciones de
        privacidad de los proveedores cuyos servicios y/o contenido incluimos y
        que son responsables de la protección de sus datos en este contexto:
      </p>
      <div className="two-paragraph">
        <p
          className="color-paragraph"
          style={{ userSelect: "none", fontWeight: "700" }}
        >
          <ul style={{ listStyle: "none" }}>
            <li>Botón Compartir de Facebook</li>
            <li>Botón Compartir de Google+</li>
            <li>Botón compartir de Twitter</li>
          </ul>
        </p>
        <p
          className="color-paragraph"
          style={{ userSelect: "none", fontWeight: "700" }}
        >
          <ul style={{ listStyle: "none" }}>
            <li>Botón Compartir de LinkedIn</li>
            <li>Video de YouTube</li>
            <li>Mapas de Google Maps</li>
          </ul>
        </p>
      </div>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        7. Información sobre eventos adversos y quejas de calidad
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Esta página no está destinada o diseñada para comunicaciones
        relacionadas a eventos adversos, falta de efecto terapéutico, errores de
        medicación, productos del mercado gris/medicamentos falsificados, uso
        incorrecto o fuera de indicación aprobada, quejas de calidad u otros
        problemas relacionados con la seguridad o la calidad de los productos de
        Bayer. Si desea informar eventos adversos o presentar un reclamo de
        calidad, comuníquese con su profesional de la salud (por ejemplo, un
        médico o farmacéutico), su autoridad sanitaria local o utilice nuestra
        página para informar sobre los eventos adversos indeseables.
      </p>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        No obstante, si nos informa sobre eventos adversos indeseables u otros
        problemas relacionados con la seguridad o la calidad de los productos de
        Bayer, estaremos legalmente obligados a procesar su comunicación y es
        posible que tengamos que comunicarnos con usted para fines de
        aclaración. Posteriormente, es posible que tengamos que notificar a las
        autoridades sanitarias competentes sobre los problemas informados por
        usted. En este contexto, su información se enviará en forma de
        seudónimo, es decir, no se transmitirá ninguna información por la que
        pueda ser identificado directamente. También es posible que tengamos que
        enviar estas notificaciones seudonimizadas a compañías pertenecientes a
        nuestro grupo y a nuestros socios de cooperación, en la medida en que
        estén igualmente obligados a notificar a sus respectivas autoridades
        sanitarias competentes.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        8. Lugar de Tratamiento de datos
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Sus datos podrán ser tratados y almacenados por nosotros directamente o
        a través de terceros en los Países Andinos, Centro América y el Caribe
        (Colombia, Costa Rica, Ecuador, El Salvador, Guatemala, Honduras,
        Panamá, Perú, República Dominicana, Venezuela) o en otras partes del
        mundo y su uso de esta herramienta nos autoriza a transferirlos y/o
        transmitirlos a otras empresas del grupo Bayer así como a otros aliados
        comerciales cuando se requiera para poner a su disposición las
        funcionalidades de la página/aplicación que usted solicitó.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        II. Transferencia para el proceso comisionado de datos
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Para el procesamiento de sus datos, utilizaremos hasta cierto punto
        proveedores de servicios especializados. Dichos proveedores de servicios
        son cuidadosamente seleccionados y monitoreados regularmente por
        nosotros. Con base en los respectivos acuerdos de procesamiento de
        datos, solo procesarán los datos personales según nuestras instrucciones
        y estrictamente de acuerdo con nuestras directivas.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        B. Información sobre sus derechos
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        En general, los siguientes derechos están disponibles según las leyes de
        Privacidad de Datos aplicables:
        <ul>
          <li>
            Derecho de acceso a la información sobre sus datos personales
            almacenados por nosotros;
          </li>
          <li>
            Derecho a solicitar la consulta, corrección o eliminación de sus
            datos personales, salvo que exista una razón legal o de interés
            público que lo impida;
          </li>
          <li>
            Derecho a presentar una queja ante una autoridad local de protección
            de datos, en caso de existir;
          </li>
          <li>
            En cualquier momento, con efecto futuro, usted puede revocar su
            consentimiento para la recolección, procesamiento y uso de sus datos
            personales. Para obtener más información consulte el módulo anterior
            que describe el procesamiento de datos en función de su
            consentimiento.
          </li>
          <li>
            Derecho a la portabilidad de los datos, en aquellas regulaciones
            donde esté autorizado.
          </li>
        </ul>
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        Contacto
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Si tiene algún problema, inquietud o sugerencia respecto al tratamiento
        de los datos personales por favor escríbanos a este correo electrónico
        aplicable a toda PACA: datospersonales.paca@bayer.com.
      </p>
      <Title style={{ color: "#633d8c", userSelect: "none" }} level={4}>
        Contacto
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        El desarrollo constante de Internet requiere ajustes ocasionales a
        nuestra declaración de privacidad. Nos reservamos el derecho de hacer
        cambios cuando sea necesario. Todas las modificaciones entran en
        vigencia una vez publicadas en nuestro sitio web. Por lo tanto,
        recomendamos que visite regularmente el sitio para mantenerse informado
        sobre posibles actualizaciones.
        
      </p>
      <p style={{ fontWeight: "700" }}>Última actualización: 05-10-2020</p>
    </div>
  );
};

export default PrivacyPolicy;
