import { createSelector } from "reselect";
import { FixString } from "../../Utils/FixString";

const selectRoom = (state) => state.rooms;

export const selectRooms = createSelector([selectRoom], (rooms) => rooms.rooms);

export const selectMovieCast = createSelector(
  [selectRoom],
  (rooms) => rooms.roomCast
);

export const selectMovieVideos = createSelector(
  [selectRoom],
  (rooms) => rooms.roomVideos
);

export const selectIsRoomsFetching = createSelector(
  [selectRoom],
  (rooms) => rooms.isFetching
);

export const selectRoomByTitle = (params) =>
  createSelector([selectRooms], (rooms) =>
    rooms.filter((room) => FixString(room.title) === params)
  );

export const selectLastUsersMeet = createSelector(
  [selectRoom],
  (rooms) => rooms.zoomLastCurrentUser
);
