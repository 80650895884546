/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
//component
import {
  ResourceUpload,
  ProfilePhoto,
  Select,
  Spinner,
  ErrorLabel,
} from "Components";
import CustomButton from "Components/CustomButton";

import Label from "Components/Header/Label";
//service
import { CompanyService } from "Services/CompanyService";
import {
  getOrganizationProfession,
  getOrganizationInteres,
  updateCurrentUserInfo,
  setNewInterests,
  setNewProfessions,
  updatePassword,
} from "Services/UserService";
//Redux
import { connect } from "react-redux";
//select
import { selectedOrganizationId } from "Redux/Organization/organization-select";
import {
  selectCurrentUser,
  selectLoadingUserData,
} from "Redux/User/user-selectors";
import { selectCompany } from "Redux/DynamicConfigCompany/dcc-select";
import {
  setNewLocalName,
  logout,
  updateUserName,
  addCurrentUserInfo,
} from "Redux/User/user-actions";
import {
  orgnizacionUnseleted,
  dccLogout,
} from "Redux/DynamicConfigCompany/dcc-action";
import { clearOrganization } from "Redux/Organization/organizarion-action";
//style
import "Components/FormInput/FormInput.css";
//Mixpanel
import Mixpanel from "Services/MixPanelService";
import { ROUTES } from "Config/config";
import { useHistory } from "react-router-dom";

const configUser = ({
  currentUser,
  organizationId,
  dataCurrentUserUpdate,
  logout,
  orgnizacionUnseleted,
  dccLogout,
  clearOrganization,
  updateUserName,
  currentInfoUser,
  loading_userInfo,
}) => {
  const { register, handleSubmit } = useForm();
  //get interes and profession company
  const [interes, setInteres] = useState();
  const [professions, setProfessions] = useState();
  //loading
  const [loading, setLoading] = useState(false);
  //get current User info
  // const [currentUser, setCurrentUserInfo] = useState({});
  //get professional id
  const [professionId, setprofessionId] = useState(null);
  //get interests od
  const [interestId, setinterestId] = useState(null);
  //Error message
  const [errorMessage, setErrorMessage] = useState(true);
  //Router redirect
  const history = useHistory();

  useEffect(() => {
    getOrganizationProfession(organizationId).then((professions) =>
      setProfessions(professions)
    );
    getOrganizationInteres(organizationId).then((interets) =>
      setInteres(interets)
    );
    //get Current User info
    currentInfoUser(currentUser.token, { organizationId });
  }, []);

  let defaultProfessionDictionary;
  let defaultProfessionNames = [];

  if (Array.isArray(currentUser.professions)) {
    let diccionario = [];
    currentUser.professions.forEach((profession) => {
      diccionario.push({ [profession.name]: profession.id });
    });
    defaultProfessionDictionary = diccionario;
    defaultProfessionDictionary.forEach((fila) =>
      defaultProfessionNames.push(Object.keys(fila)[0])
    );
  }

  let defaultInterestsDiccionary;
  let defaultInterestsName = [];

  if (Array.isArray(currentUser.interests)) {
    let diccionario = [];
    currentUser.interests.forEach((interests) => {
      diccionario.push({ [interests.name]: interests.id });
    });
    defaultInterestsDiccionary = diccionario;

    defaultInterestsDiccionary.forEach((fila) =>
      defaultInterestsName.push(Object.keys(fila)[0])
    );
  }
  const handleChangeSelect = (selectType, selectedItems) => {
    if (selectType === "prefessionId") {
      let finalProfessionId = [];
      selectedItems.forEach((item) => {
        if (typeof item === "string") {
          defaultProfessionDictionary.forEach((itemDiccionarie) => {
            if (itemDiccionarie[item]) {
              finalProfessionId.push(itemDiccionarie[item]);
            }
          });
        } else {
          finalProfessionId.push(item);
        }
      });
      setprofessionId(finalProfessionId);
    }

    if (selectType === "interesId") {
      let finalInteresId = [];
      selectedItems.forEach((item) => {
        if (typeof item === "string") {
          defaultInterestsDiccionary.forEach((itemDiccionarie) => {
            if (itemDiccionarie[item]) {
              finalInteresId.push(itemDiccionarie[item]);
            }
          });
        } else {
          finalInteresId.push(item);
        }
      });
      setinterestId(finalInteresId);
    }
  };

  const onSubmit = async (data) => {
    const {
      bio,
      confirmPassword,
      password,
      firstLastName,
      secondLastName,
      phone,
      name,
      professionalCode,
    } = data;

    if (!phone || !name || !firstLastName || !bio) {
      setErrorMessage("Los elementos que tengan [*] son requeridos");
      return;
    }
    if (
      phone === currentUser.phone &&
      name === currentUser.name &&
      firstLastName === currentUser.firstLastName &&
      secondLastName === currentUser.secondLastName &&
      bio === currentUser.bio &&
      professionalCode === currentUser.professionalCode &&
      !password &&
      !interestId &&
      !professionId
    ) {
      setErrorMessage("No actualizo ningun elemento");
      return;
    }

    if (password || confirmPassword) {
      if (confirmPassword !== password) {
        setErrorMessage("Las contraseñas no coinciden");
        return;
      } else {
        updatePassword(currentUser.token, organizationId, password);
      }

      const newData = {
        bio,
        firstLastName,
        secondLastName,
        phone,
        name,
        professionalCode,
      };

      if (interestId !== null) {
        setNewInterests(currentUser.token, organizationId, interestId);
      }

      if (professionId !== null) {
        setNewProfessions(currentUser.token, organizationId, professionId);
      }
      setLoading(true);
      const status = await updateCurrentUserInfo(
        currentUser.token,
        organizationId,
        newData
      );

      updateUserName({
        name: newData.name,
        firstLastName: newData.firstLastName,
        secondLastName: newData.secondLastName,
      });
      //Mixpanel
      Mixpanel.track(Mixpanel.TYPES.UPDATE_USER_DATA, {
        userId: currentUser.id,
        organization: organizationId,
      });

      if (status === 406) {
        setErrorMessage(
          "Hubo un problema al actualizar la cuenta. Por favor vuelva a intentarlo)"
        );
        return;
      }
      //redirect to sign in when the user change the password
      if (!loading) {
        const companyData = await CompanyService.getCompanyData(
          window.location.host
        ).then((data) => data);
        //this route redirect to home
        dataCurrentUserUpdate(name);
        orgnizacionUnseleted(companyData.company);
        clearOrganization();
        dccLogout();
        logout(false, true);
      }
    } else {
      const newData = {
        bio,
        firstLastName,
        secondLastName,
        phone,
        name,
        professionalCode,
      };

      if (interestId !== null) {
        setNewInterests(currentUser.token, organizationId, interestId);
      }

      if (professionId !== null) {
        setNewProfessions(currentUser.token, organizationId, professionId);
      }
      setLoading(true);
      const status = await updateCurrentUserInfo(
        currentUser.token,
        organizationId,
        newData
      );

      updateUserName({
        name: newData.name,
        firstLastName: newData.firstLastName,
        secondLastName: newData.secondLastName,
      });

      //Mixpanel
      Mixpanel.track(Mixpanel.TYPES.UPDATE_USER_DATA, {
        userId: currentUser.id,
        organization: organizationId,
      });
      setLoading(false);

      if (!loading) {
        dataCurrentUserUpdate(name);
        history.push(ROUTES.HOME);
      }

      if (status === 406) {
        setErrorMessage(
          "Hubo un problema al actualizar la cuenta. Por favor vuelva a intentarlo)"
        );
        return;
      }
    }
  };
  return (
    <div
      className="flex flex-col justify-center mt-24 mx-24 max-md:mx-3"
      // style={{ width: "85vw" }}
    >
      {loading && <Spinner />}
      <h2 className="text-primary-title font-bold text-center text-3xl">
        Gestión de cuenta
      </h2>
      <div className="w-full text-center">
        <ResourceUpload id="profilePhoto" className="">
          <ProfilePhoto
            profilePhotoSrc={currentUser?.profileImageUser}
            containerStyle={{
              right: "5px",
              bottom: "2px",
              maxHeight: "200px",
              maxWidth: "200px",
            }}
            avatarStyle={{
              width: "200px",
              height: "200px",
            }}
            cameraStyle={{
              left: "30px",
              bottom: "0",
              fontSize: "2rem",
            }}
          />
        </ResourceUpload>
      </div>

      <form
        className="flex w-full justify-center flex-col shadow-none"
        actiFormon="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        {loading_userInfo ? (
          <Spinner />
        ) : (
          <>
            <div className="w-full h-full grid grid-rows-1 grid-cols-2 max-md:grid-cols-1 gap-2">
              <div className="w-full">
                <Label $InputTittle>Nombre* </Label>
                <input
                  maxLength={100}
                  name="name"
                  defaultValue={currentUser.name}
                  {...register("name")}
                  className="w-full bg-primary-input-user-config text-primary-input
                  mr-4 py-3 pl-3 pr-2 mb-3 border-none rounded-sm text-sm block"
                />
                <Label $InputTittle>Primer Apellido* </Label>
                <input
                  defaultValue={currentUser.firstLastName}
                  maxLength={100}
                  {...register("firstLastName")}
                  required
                  className="w-full bg-primary-input-user-config text-primary-input
                  mr-4 py-3 pl-3 pr-2 mb-3 border-none rounded-sm text-sm block"
                />
                <Label $InputTittle>Segundo Apellido </Label>
                <input
                  defaultValue={currentUser.secondLastName}
                  {...register("secondLastName")}
                  required
                  className="w-full bg-primary-input-user-config text-primary-input
                  mr-4 py-3 pl-3 pr-2 mb-3 border-none rounded-sm text-sm block"
                />
                <Label $InputTittle>Describa su recorrido profesional*: </Label>
                <textArea
                  {...register("bio")}
                  className="w-full bg-primary-input-user-config text-primary-input
                  mr-4 py-3 pl-3 pr-2 mb-3 border-none rounded-sm text-sm block"
                >
                  {currentUser.bio}
                </textArea>
                <Label $InputTittle>Intereses </Label>
                <Select
                  height="auto"
                  name="interesId"
                  mode="tags"
                  width="100%"
                  color="000000d9"
                  margin="0px 0px 15px 0px"
                  defaultValue={defaultInterestsName}
                  onChange={(value) => handleChangeSelect("interesId", value)}
                >
                  {Array.isArray(interes) &&
                    interes.map((interes, idx) => (
                      <Select.Option key={idx} value={interes.id}>
                        {interes.name}
                      </Select.Option>
                    ))}
                </Select>
              </div>
              <div style={{ width: "100%" }}>
                <Label $InputTittle>Teléfono* </Label>
                <input
                  maxLength={20}
                  defaultValue={currentUser.phone}
                  {...register("phone")}
                  required
                  className="w-full bg-primary-input-user-config text-primary-input
                  mr-4 py-3 pl-3 pr-2 mb-3 border-none rounded-sm text-sm block"
                />
                <Label $InputTittle>Nueva contraseña* </Label>
                <input
                  type="password"
                  {...register("password")}
                  maxLength={50}
                  minLength={8}
                  id="password"
                  className="w-full bg-primary-input-user-config text-primary-input
                  mr-4 py-3 pl-3 pr-2 mb-3 border-none rounded-sm text-sm block"
                />
                <Label $InputTittle>Confirmar nueva contraseña* </Label>
                <input
                  type="password"
                  {...register("confirmPassword")}
                  id="requiredPassword"
                  className="w-full bg-primary-input-user-config text-primary-input
                  mr-4 py-3 pl-3 pr-2 mb-3 border-none rounded-sm text-sm block"
                />

                <Label $InputTittle>Código profesional </Label>
                <textArea
                  name="professionalCode"
                  {...register("professionalCode")}
                  className="w-full bg-primary-input-user-config text-primary-input
                  mr-4 py-3 pl-3 pr-2 mb-3 border-none rounded-sm text-sm block"
                >
                  {currentUser.professionalCode}
                </textArea>
                <Label $InputTittle>Profesiones </Label>
                <Select
                  height="auto"
                  name="professionId"
                  mode="tags"
                  width="100%"
                  defaultValue={defaultProfessionNames}
                  color="000000d9"
                  margin="0px 0px 15px 0px"
                  onChange={(value) =>
                    handleChangeSelect("prefessionId", value)
                  }
                >
                  {Array.isArray(professions) &&
                    professions.map(({ id, name }, idx) => (
                      <Select.Option key={idx} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                </Select>
              </div>
            </div>
            <CustomButton $ButtonConfigUser>Actualizar</CustomButton>
          </>
        )}
        <ErrorLabel value={errorMessage} margin="10px 0" />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  organizationId: selectedOrganizationId(state),
  company: selectCompany(state),
  loading_userInfo: selectLoadingUserData(state),
});
const mapDispatchToProps = (dispatch) => ({
  dataCurrentUserUpdate: (name) => dispatch(setNewLocalName(name)),
  logout: (sessionExpired, reloadLogin) =>
    dispatch(logout(sessionExpired, reloadLogin)),
  orgnizacionUnseleted: (company) => dispatch(orgnizacionUnseleted(company)),
  dccLogout: () => dispatch(dccLogout()),
  clearOrganization: () => dispatch(clearOrganization()),
  updateUserName: (whereOps) => dispatch(updateUserName(whereOps)),
  currentInfoUser: (token, whereOps) =>
    dispatch(addCurrentUserInfo(token, whereOps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(configUser);
