//Libs
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { BarChartOutlined } from "@ant-design/icons";
import { Element } from "react-scroll";
//Icons
// import { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
//Css
import "./Superchat.css";
//Checks
import {
  checkIfInputIsAnArrayValid,
  checkIfPollAnswersIsValid,
} from "Components/SuperChat/checks";

//Styles
import {
  // EmojiPicker,
  EmoticonIcons,
  MessageCard,
  PollCard,
  ReactionPicker,
} from "Components/SuperChatStyled";
//Components
import { ChartResults, FloatingReaction, PollForm } from "Components/index";
import { default as userPermission } from "Utils/Permissions";
//Redux
import { connect } from "react-redux";
//video action
import { sentCheckPollAnswer } from "Redux/Video/video-actions";
import { selectCheckUserAlreadyVote } from "Redux/Video/video-selectors";
import NewMessagesButton from "Components/NewMessagesButton";

const Superchat = ({
  fullScreen,
  mobileView,
  //polls
  polls = [],
  pollAnswers,
  onAnswerPoll,
  messages = [],
  userId,
  roomId,
  newMessageIsQuestion,
  //Actions
  onCreatePoll,
  closeLocal,
  onMessageInputEnterKeyPressed,
  onMessageInputChange,
  sendNewReaction,
  stopShowingReaction,
  reactions,
  selectCheckUserAlreadyVote,
  setCheckAlreadyVote,
  permissions,
  //eventIsStarted
  eventIsStarted,
  onChangeIsQuestion,
  showNewMessagesButton,
  onClickNewMessagesButton,
  setMessageEndRef,
  firstLoad,
}) => {
  //scroll end when message send
  const messagesEndRef = useRef(null);

  const [messageChat, setMessage] = useState("");

  //show results finish
  const handleFinishSurvey = (poll) => {
    if (!checkIfPollAnswersIsValid(poll)) {
      return;
    }

    setCheckAlreadyVote(false);
    closeLocal(poll, true);
  };
  //scroll bottom effect when user send a message
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messages.length > 0) {
      const lastId = messages.length - 1;
      const lastMessage = document.getElementById(`message_${lastId}`);
      if (lastMessage && firstLoad) {
        scrollToBottom(firstLoad);
      }
    }
  }, [firstLoad, messages]);

  return (
    <div className="bg-chat_color w-screen md:w-auto h-full flex flex-col justify-between">
      {reactions.map((r, index) => {
        return (
          <FloatingReaction
            stopShowingReaction={stopShowingReaction}
            key={`reaction_${index}`}
            reaction={r}
          />
        );
      })}

      <div
        className="mt-0 overflow-x-auto py-3 px-3 pr-0 bg-mesg_cont_color"
        style={{
          height: mobileView
            ? `calc(100vh - ${window.innerWidth * 0.5625}px - 110px)`
            : "calc(100vh - 110px)",
        }}
      >
        ){/* Messages*/}
        {showNewMessagesButton && (
          <NewMessagesButton onClick={onClickNewMessagesButton} />
        )}
        {checkIfInputIsAnArrayValid(messages).map((message, idx) => (
          <MessageCard
            key={idx}
            idx={idx}
            currentUserId={userId}
            message={message}
          />
        ))}
        <div
          className="float-left clear-both"
          id="lastMessage"
          ref={(el) => {
            setMessageEndRef(el);
          }}
        />
        {/*Se tiene que validar que la encuesta esta activa para que al momento de*/}
        {/*renderizar no se borre Polls*/}
        {polls.map((poll) =>
          selectCheckUserAlreadyVote || poll.userId === userId ? (
            //grafico
            <>
              <ChartResults pollAnswers={pollAnswers[poll.id]} />
              {poll.userId === userId &&
              poll.isOpen &&
              Object.entries(permissions).length !== 0 &&
              permissions?.Polls.includes(userPermission.USER_CAN_CREATE) ? (
                <div className="mt-5">
                  <div>
                    <button
                      className="btn bg-card-button-color text-card-color"
                      onClick={() => handleFinishSurvey(poll)}
                      disabled={
                        permissions?.Polls.includes(
                          userPermission.USER_CAN_CREATE
                        )
                          ? false
                          : true
                      }
                    >
                      Terminar
                    </button>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <Element name={poll.id} id="PollCard">
              <PollCard
                checkAnswer={setCheckAlreadyVote}
                userVoteYet={selectCheckUserAlreadyVote}
                poll={poll}
                onAnswerPoll={onAnswerPoll}
              />
            </Element>
          )
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-1 bg-mesg_cont_color ">
        <input
          className="checkbox bg-checkbox-question"
          onChange={(evt) => {
            onChangeIsQuestion(evt.target.checked);
          }}
          type="checkbox"
          checked={newMessageIsQuestion}
          disabled={!eventIsStarted}
        />
        <h5 className="text-tittle-question font-2xs inline ml-1 relative bottom-1">
          Marcar como pregunta
        </h5>

        <div className="grid grid-cols-chat" id="input-container">
          <input
            className="w-full h-11 px-3 bg-input-msg"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value !== "") {
                onMessageInputEnterKeyPressed(e);
                setMessage("");
              }
            }}
            disabled={!eventIsStarted}
            placeholder={
              eventIsStarted ? "Envie su mensaje..." : "Chat no disponible..."
            }
            type="text"
            value={messageChat}
            onChange={(e) => {
              setMessage(e.target.value);
              onMessageInputChange(e);
            }}
          />
          <EmoticonIcons fullScreenChat={fullScreen}>
            {Object.entries(permissions).length !== 0 ? (
              permissions?.Polls.includes(userPermission.USER_CAN_CREATE) ? (
                <Popup
                  className="bg-popop"
                  trigger={<BarChartOutlined />}
                  modal
                >
                  {(close) => (
                    <PollForm
                      roomId={roomId}
                      userId={userId}
                      close={close}
                      permissions={permissions.Polls}
                      polls={polls}
                      onCreatePoll={onCreatePoll}
                    />
                  )}
                </Popup>
              ) : null
            ) : null}
          </EmoticonIcons>
          <ReactionPicker
            onSelect={(e) => {
              sendNewReaction(e, true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectCheckUserAlreadyVote: selectCheckUserAlreadyVote(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCheckAlreadyVote: () => dispatch(sentCheckPollAnswer()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Superchat));
