import React from "react";
import "./Switch.css";

const Switch = ({ isOn, handleToggle }) => {
  return (
    <>
      <div class="flex items-center justify-center w-full">
        <label for="toggleB" class="flex items-center cursor-pointer">
          <div class="relative">
            <input
              type="checkbox"
              id="toggleB"
              class="sr-only"
              checked={isOn}
              onChange={handleToggle}
            />
            <div class="block bg-switch-color w-14 h-8 rounded-full"></div>
            <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
          </div>
        </label>
      </div>
    </>
  );
};

export default Switch;
