/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
// import { useAutosave } from "react-autosave";
//Component
import { useForm } from "react-hook-form";
import { FiAlertCircle } from "react-icons/fi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { ROUTES } from "Config/config";
import { SpinnerButton } from "Components";
//Service
import { sendQuestionAnswers, setQuizCode } from "Services/QuestionQuizService";
//redux
import { connect } from "react-redux";
//action
import {
  setQuestionData,
  clearQuestion,
  setQuestionResult,
} from "Redux/Questions/questions-action";
//select
import { selectedQuizCode } from "Redux/Questions/question-selector";
import { selectCompanyId } from "Redux/DynamicConfigCompany/dcc-select";
import { Spinner } from "Components";

const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

// const saveData = async ({
//   companyId,
//   isDone = false,
//   quizcode,
//   RadioValue,
//   setLoadinSaveData,
//   setMsg,
// }) => {
//   let arrayAnswer = Object.values(RadioValue);
//   const newArray = arrayAnswer.filter((value) => value !== null);
//   console.log("entro aca al auto save");
//   //send save data
//   setLoadinSaveData(true);
//   const statud = await sendQuestionAnswers({
//     companyId,
//     newArray,
//     isDone,
//     quizzCode: quizcode,
//   }).then((statud) => statud);

//   if (statud.status === 201) {
//     if (!isDone) {
//       setMsg({ successMsg: "Sus repuestas han sido guardadas con éxito. " });
//     }
//   } else {
//     if (statud.status === 404) {
//       setMsg({ errorMsg: "El codigo no es valido, intente de nuevo" });
//       setTimeout(() => {
//         setMsg({ errorMsg: null, successMsg: null });
//       }, 30000);
//     }
//     setMsg({
//       errorMsg:
//         "Ocurrió un problema, inténtelo de nuevo, si el problema persiste comuníquese con soporte.",
//     });
//     setTimeout(() => {
//       setMsg({ errorMsg: null, successMsg: null });
//     }, 5000);
//   }

//   setLoadinSaveData(false);
// };

const getMoreItems = ({ typeOfPage, currentPage }) => {
  let currentPageInfo = currentPage;
  if (typeOfPage === "nextPage") {
    currentPageInfo = currentPage + 1;
  } else if (typeOfPage === "previousPage") {
    currentPageInfo = currentPage - 1;
  }

  return currentPageInfo;
};

const QuizPage = ({
  companyId,
  quizcode,
  clearQuestion,
  setQuestionData,
  getResult,
}) => {
  const [loading, setLoading] = useState(false);
  //this is answer send loading
  const [lodingAnswer, setLoadingAnswer] = useState(false);
  //this is loading save data
  const [loadinSaveData, setLoadinSaveData] = useState(false);
  //message
  const [msg, setMsg] = useState({ errorMsg: null, successMsg: null });
  //question
  const [questionDataState, setQuestionDataState] = useState({
    question: null,
    pagination: null,
  });
  //history
  const history = useHistory();
  //useForm
  const {
    register,
    handleSubmit,
    // getValues,
    reset,
    formState: { errors },
  } = useForm();

  //auto save
  // useAutosave({
  //   interval: 10000,
  //   updateBlog: saveData({
  //     companyId,
  //     questionDataState,
  //     quizcode,
  //     RadioValue: getValues(),
  //     setLoadinSaveData,
  //     setMsg,
  //   }),
  // });

  const { questions, pagination } = questionDataState;

  useEffect(() => {
    async function loadingAllQuiz() {
      setLoading(true);
      const statud = await setQuizCode({ code: quizcode, companyId }).then(
        (response) => response
      );
      const response = await setQuizCode({ code: quizcode, companyId }).then(
        (response) => response.json()
      );
      setLoading(false);
      if (statud.status !== 200) {
        clearQuestion({ clearAnswer: true });
        history.push(ROUTES.LADING_PAGE);
      } else {
        setQuestionDataState({
          questions: response?.questions?.rows,
          pagination: response.paginationData,
        });
        setQuestionData(response);
      }
    }
    loadingAllQuiz();

    return () => {
      reset();
    };
  }, []);

  const onSubmit = async (data) => {
    let arrayAnswer = Object.values(data);
    const newArray = arrayAnswer.filter((value) => value !== null);

    const isDone =
      pagination?.currentPage === pagination?.totalPages ? true : false;

    setLoadingAnswer(true);

    const responseData = {
      status: null,
      fetchData: null,
    };

    await sendQuestionAnswers({
      companyId,
      newArray,
      isDone,
      quizzCode: quizcode,
    })
      .then((response) => {
        responseData.status = response.status;
        return response.json();
      })
      .then((data) => (responseData.fetchData = data.finalQuestions));

    const { status, fetchData } = responseData;

    if (status === 201) {
      if (!isDone) {
        setMsg({ successMsg: "Sus repuestas han sido guardadas con éxito. " });
        setLoadinSaveData(true);

        const response = await setQuizCode({ code: quizcode, companyId }).then(
          (response) => response.json()
        );
        setQuestionDataState({
          questions: response?.questions?.rows,
          pagination: response.paginationData,
        });

        setQuestionData(response);
        setLoadinSaveData(false);
        setTimeout(() => {
          setMsg({ errorMsg: null, successMsg: null });
        }, 5000);
      }

      if (isDone) {
        clearQuestion({ clearAnswer: true });
        getResult(fetchData);
        history.push(ROUTES.RESULT);
      }
    } else {
      if (status === 404) {
        setMsg({ errorMsg: "El codigo no es valido, intente de nuevo" });
        setTimeout(() => {
          clearQuestion({ clearAnswer: true });
          history.push(ROUTES.LADING_PAGE);
          setMsg({ errorMsg: null, successMsg: null });
        }, 30000);
      }
      setMsg({
        errorMsg:
          "Ocurrió un problema, inténtelo de nuevo, si el problema persiste comuníquese con soporte.",
      });
      setTimeout(() => {
        setMsg({ errorMsg: null, successMsg: null });
      }, 5000);
    }
    setLoadingAnswer(false);
  };

  return (
    <div
      className="w-screen min-h-screen bg-sign-in-background bg-cover
    bg-center flex justify-center md:pt-28 md:pb-14"
    >
      {!loading ? (
        <div
          className="w-full h-full md:w-vw-34 lg:w-vw-24 md:py-8 glass md:rounded-lg flex : ;
        flex-col items-center px-4 py-4 md:px-10"
        >
          <span className="text-primary-label-input lg:text-4xl font-primary-font-family mb-3">
            Conteste la siguientes preguntas
          </span>
          <form
            // key={index}
            className="w-full flex flex-col"
            onSubmit={handleSubmit(onSubmit)}
          >
            {Array.isArray(questions) &&
              questions.map((valueX, indeX) => (
                <Fragment key={indeX}>
                  <span className="text-white py-1 text-xl">
                    {valueX.id}) {valueX.question}
                  </span>
                  <span className="text-primary-label-input text-lg my-4">
                    Seleccione una opción:
                  </span>
                  <div className="flex flex-row w-full justify-center">
                    {valueX.answers?.map((valueY, indexY) => (
                      <div key={indexY}>
                        <label className="label">
                          <input
                            type="radio"
                            id={`checkbox${valueX.id}`}
                            value={valueY.id}
                            className="radio-accent radio-md"
                            defaultChecked={valueY.leads.length ? true : false}
                            {...register(`checkbox${valueX.id}`, {
                              required:
                                questions &&
                                pagination?.currentPage ===
                                  pagination?.totalPages
                                  ? true
                                  : false,
                            })}
                          />
                          <span className="label-text mx-3 text-base lg:text-lg">
                            {valueY.text}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </Fragment>
              ))}
            <div className="flex flex-row justify-between basis-6">
              <button
                type="submit"
                className={`btn my-2 w-full bg-primary-button 
                hover:bg-primary-hover-button`}
              >
                {loadinSaveData || lodingAnswer ? <SpinnerButton /> : ""}

                {questions && pagination?.currentPage === pagination?.totalPages
                  ? lodingAnswer
                    ? "Enviando"
                    : "Enviar"
                  : loadinSaveData
                  ? "Guardando"
                  : "Guardar"}
              </button>
            </div>
            {msg.errorMsg && (
              <div className="alert alert-error py-2">
                <div className="flex items-center justify-center ">
                  <FiAlertCircle className="w-6 h-6 mx-2 stroke-current" />
                  <span>{msg.errorMsg}</span>
                </div>
              </div>
            )}
            {msg.successMsg && (
              <div className="py-1 text-left alert alert-success">
                <div className="flex-1">
                  <AiOutlineCheckCircle className="w-6 h-6 mx-2 stroke-current" />
                  <span className="items-start font-semibold">
                    {msg.successMsg}
                  </span>
                </div>
              </div>
            )}
            {!isObjEmpty(errors) && (
              <div class="alert alert-error py-2">
                <div class="flex items-center justify-center ">
                  <FiAlertCircle className="w-6 h-6 mx-2 stroke-current" />
                  <span>Tiene que contestar todas las preguntas</span>
                </div>
              </div>
            )}
          </form>
          <div className="btn-group">
            <button
              className="btn btn-outline btn-wide"
              disabled={
                pagination && pagination?.currentPage === 0 ? true : false
              }
              onClick={async () => {
                setLoading(true);
                const previousPage = getMoreItems({
                  typeOfPage: "previousPage",
                  currentPage: pagination?.currentPage,
                });
                const response = await setQuizCode({
                  code: quizcode,
                  companyId,
                  currentPage: previousPage,
                }).then((response) => response.json());
                setQuestionDataState({
                  questions: response?.questions?.rows,
                  pagination: response.paginationData,
                });
                setLoading(false);
                setQuestionData(response);
              }}
            >
              Página anterior
            </button>
            <button
              className={`btn btn-outline btn-wide `}
              disabled={
                pagination && pagination?.currentPage === pagination?.totalPages
                  ? true
                  : false
              }
              onClick={async () => {
                setLoading(true);
                const nextPage = getMoreItems({
                  typeOfPage: "nextPage",
                  currentPage: pagination?.currentPage,
                });
                const response = await setQuizCode({
                  code: quizcode,
                  companyId,
                  currentPage: nextPage,
                }).then((response) => response.json());
                setQuestionDataState({
                  questions: response?.questions?.rows,
                  pagination: response.paginationData,
                });
                setLoading(false);
                setQuestionData(response);
              }}
            >
              Siguiente Pagina
            </button>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyId: selectCompanyId(state),
  quizcode: selectedQuizCode(state),
});

const mapDispatchToProps = (dispatch) => ({
  setQuestionData: (whereOps) => dispatch(setQuestionData(whereOps)),
  clearQuestion: ({ clearAnswer }) => dispatch(clearQuestion({ clearAnswer })),
  getResult: (result) => dispatch(setQuestionResult(result)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizPage);
