import React, { useState } from "react";
//Component
import TextField from "@material-ui/core/TextField";
import Switch from "./Switch/Switch";
import { MdPlaylistAdd } from "react-icons/md";
import { FiDelete } from "react-icons/fi";
import { IoIosAddCircleOutline } from "react-icons/io";
//Service
import MixPanel from "Services/MixPanelService";
//utils
import { default as userPermission } from "Utils/Permissions";

const PollForm = ({ onCreatePoll, roomId, polls, permissions }) => {
  const [valueSwitch, setValueSwitch] = useState(false);
  const [option, setOption] = useState("");
  const [answerOption, setAnswerOption] = useState([]);
  const [errorAlert, setAlert] = useState(false);
  const [pollContent, setPollContent] = useState("");

  const addAnswerOptions = () => {
    //Input value Validation
    if (option.trim() === "") {
      const alert = {
        msg: "Por favor, digite una opción.",
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      setAlert(alert);
      setTimeout(() => setAlert(false), 1500);
      return;
    }
    if (answerOption.findIndex((opt) => opt.value === option) !== -1) {
      const alert = {
        msg: "La opción ingresada ya existe",
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      setAlert(alert);
      setTimeout(() => setAlert(false), 1500);
      return;
    }
    setAlert(false);
    setAnswerOption([
      ...answerOption,
      {
        value: option,
      },
    ]);
    setOption("");
  };

  //Create and put poll in redux state
  const createPoll = () => {
    if (pollContent.trim() === "" || answerOption.length < 2) {
      const alert = {
        msg: "Todos los campos son obligatorios, debe digitar una pregunta y al menos dos opciones.",
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      setAlert(alert);
      return;
    }

    if (polls.length >= 1) {
      const alert = {
        msg: "Ya se encuentra una encuesta activa.",
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      setAlert(alert);
      return;
    }

    setAlert(false);
    //Aca se envia los datos del poll al service
    onCreatePoll({
      question: pollContent,
      roomId: roomId,
      multiAnswer: valueSwitch,
      optionValue: answerOption,
    });
  };

  //delete answer option in form
  const deleteAnswerOption = (value) => {
    MixPanel.track(MixPanel.TYPES.DELETE_ANSWER_OPTION_POLL);
    setAnswerOption(answerOption.filter((option) => option.value !== value));
  };

  const arrayAnswerOptions = answerOption;

  return (
    <div
      className="flex flex-col relative py-3 px-0 bg-conteiner-poll overflow-auto 
    h-115 overflow-x-hidden"
    >
      <form>
        <div
          className="flex flex-col overflow-hidden relative bg-group-input-poll ml-3
         mr-3 mb-2 rounded-xl"
        >
          <h5 className="text-poll-title text-center mt-3">Pregunta</h5>
          <TextField
            id="filled-basic"
            label="Escribe tu pregunta."
            variant="filled"
            color="primary"
            className="bg-white m-3"
            value={pollContent}
            onChange={(e) => {
              setPollContent(e.target.value);
            }}
          />
        </div>

        <div
          className="flex flex-col overflow-hidden relative bg-group-input-poll ml-3 mr-3 
        mb-2 rounded-xl"
        >
          <h5 className="text-poll-title text-center mt-3">Respuestas</h5>
          <div className="flex flex-col relative overflow-x-hidden bg-transparent">
            <TextField
              id="filled-basic"
              label="Escriba y agregue una opción"
              variant="filled"
              color="primary"
              className="bg-white m-3"
              value={option}
              onChange={(e) => setOption(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  MixPanel.track(
                    MixPanel.TYPES.USER_SELECT_OPCION_QUESTION_POLL
                  );
                  addAnswerOptions();
                }
              }}
            />
            <button
              className="flex absolute right-5 h-12 w-12 justify-center items-center 
              text-poll-btn-color hover:text-poll-btn-hover text-2xl mt-4 bg-transparent
               outline-offset-2 transition"
              onClick={(e) => {
                e.preventDefault();
                if (option.trim() !== "") {
                  MixPanel.track(
                    MixPanel.TYPES.USER_SELECT_OPCION_QUESTION_POLL
                  );
                  addAnswerOptions();
                }
              }}
            >
              <MdPlaylistAdd />
            </button>
          </div>
          {arrayAnswerOptions.map((option, idx) => (
            <div key={idx} className="m-3">
              <span>
                {idx + 1}) {option.value}
              </span>
              <FiDelete
                className="text-primary-label-msg text-lg cursor-pointer m-1 
                float-right relative bottom-1 hover:text-hover-link"
                onClick={() => deleteAnswerOption(option.value)}
              />
            </div>
          ))}
        </div>

        <div
          className="flex flex-col relative py-3 px-0 bg-conteiner-poll 
        overflow-auto h-115 overflow-x-hidden"
        >
          <h5 className="text-poll-title text-center mt-3">
            Opciones Adicionales
          </h5>
          <h6 className="text-poll-sbt-color ml-6">Respuesta Múltiple</h6>
          <div className="absolute bottom-0 right-10">
            <Switch
              isOn={valueSwitch}
              handleToggle={() => {
                setValueSwitch(!valueSwitch);
                MixPanel.track(MixPanel.TYPES.CREATE_MULTIPLE_OPCION_POLL);
              }}
            />
          </div>
        </div>

        <div className="flex flex-col relative py-3 px-2 bg-conteiner-poll overflow-auto h-115 overflow-x-hidden">
          {errorAlert ? (
            <p className={errorAlert.classes}>{errorAlert.msg}</p>
          ) : null}
          <button
            type="button"
            onClick={() => {
              createPoll();
              MixPanel.track(MixPanel.TYPES.CREATE_POLL);
            }}
            className="w-100 btn bg-primary-button hover:bg-primary-hover-button
             border-none"
            disabled={
              permissions.includes(userPermission.USER_CAN_CREATE)
                ? false
                : true
            }
          >
            <IoIosAddCircleOutline className="text-lg cursor-pointer ml-10 m-1" />
            Crear
          </button>
        </div>
      </form>
    </div>
  );
};

export default PollForm;
