import { API_URL } from "Config/config";
const quizBase = `${API_URL}organizations`;

export const setAssigment = async ({
  file,
  homeworkId,
  organizationId,
  resourceId,
  token,
}) => {
  const formData = new FormData();
  formData.append("homework", file[0]);

  const options = {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: formData,
  };

  return fetch(
    `${quizBase}/${organizationId}/resources/${resourceId}/homeworks/${homeworkId}`,
    options
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getAssigmentSpecificResource = async ({
  resourceId,
  token,
  organizationId,
}) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: token,
    },
  };

  return fetch(
    `${quizBase}/${organizationId}/resources/${resourceId}/homeworks`,
    options
  )
    .then((response) => response)
    .catch((error) => error);
};

export const deleteAssigment = async ({
  resourceId,
  token,
  organizationId,
  homeworkId,
}) => {
  const options = {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  };

  return fetch(
    `${quizBase}/${organizationId}/resources/${resourceId}/homeworks/${homeworkId}`,
    options
  );
};

export const setAllAssigment = async ({
  organizationId,
  resourceId,
  token,
  confirmation,
}) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      forceSave: confirmation,
    }),
  };
  return fetch(
    `${quizBase}/${organizationId}/resources/${resourceId}/homeworks/finish`,
    options
  )
    .then((response) => response)
    .catch((error) => error);
};
