import UserActionTypes from "./user-types";
//service
import { getSpecificUserCurrentInfo } from "Services/UserService";
//logout
import { CompanyService } from "Services/CompanyService";
import { orgnizacionUnseleted } from "Redux/DynamicConfigCompany/dcc-action";
import { dccLogout } from "Redux/DynamicConfigCompany/dcc-action";
import { clearOrganization } from "Redux/Organization/organizarion-action";

export const setCurrentUser = (user) => {
  return {
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user,
  };
};
export const setNavMenuOpen = (open) => {
  return {
    type: UserActionTypes.SET_NAV_MENU_OPEN,
    payload: open,
  };
};

export const logout = (sessionExpired, reloadLogin) => {
  return {
    type: UserActionTypes.LOGOUT,
    payload: {
      sessionExpired,
      reloadLogin,
    },
  };
};

export const sendMessage = (payload) => ({
  type: UserActionTypes.SHOW_MESSAGE,
  payload,
});

export const setProfilePhotoUpdated = () => ({
  type: UserActionTypes.SET_PROFILE_PHOTO_UPDATED,
});

export const setHideTour = () => ({
  type: UserActionTypes.HIDE_TOUR,
});

export const setProfilePhotoChanged = () => ({
  type: UserActionTypes.SET_PROFILE_PHOTO_CHANGED,
});

export const setRecoveryPasswordToken = (token) => ({
  type: UserActionTypes.TOKEN_RECOVERY_PASSWORD,
  payload: token,
});

export const changeModalUserInfoState = (payload) => ({
  type: UserActionTypes.CONFIG_USER_MODAL_STATE,
  payload,
});

export const setNewLocalName = (payload) => ({
  type: UserActionTypes.UPDATE_LOCAL_CURRENT_NAME,
  payload,
});
//get profile user photo
export const setCurrentProfilePhoto = (payload) => ({
  type: UserActionTypes.GET_PROFILE_PHOTO,
  payload,
});

export const changeMessageWhenIsExpired = () => ({
  type: UserActionTypes.CHANGE_STATUS_MESSAGE_EXPIRED,
});

export const changeStatePassConfigUser = () => ({
  type: UserActionTypes.RELOADING_MESSAGE,
});

export const updateUserName = (payload) => ({
  type: UserActionTypes.CHANGE_REDUX_NAME_USER,
  payload,
});

// this accion is for update currentData when the user open the edit profile
export const addCurrentUserInfo = (token, { organizationId }) => {
  return (dispatch) => {
    if (!token) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido para obtener las noticias",
        },
      });
      return;
    }
    dispatch({
      type: UserActionTypes.LOADING_USER_DATA,
    });

    getSpecificUserCurrentInfo(token, organizationId).then(async (userData) => {
      if (userData === 401) {
        const companyData = await CompanyService.getCompanyData(
          window.location.host
        ).then((data) => data);
        dispatch(orgnizacionUnseleted(companyData.company));
        dispatch(clearOrganization());
        dispatch(dccLogout());
        dispatch(logout(true));
      } else {
        dispatch({
          type: UserActionTypes.ADD_CURRENT_USER_INFO,
          payload: userData,
        });
      }
    });
  };
};
