/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import _ from "lodash";
//Componentes
import Spinner from "Components/Spinner/Spinner";
//config
import { ROUTES } from "Config/config";
//redux
import { connect } from "react-redux";
import {
  selectOrganizacion,
  selectCompanyStyle,
} from "Redux/DynamicConfigCompany/dcc-select";
import { setModuleOrganization } from "Redux/DynamicConfigCompany/dcc-action";
import {
  setOrganizationSeleted,
  clearOrganization,
} from "Redux/Organization/organizarion-action";
import { selectedOrganizationId } from "Redux/Organization/organization-select";
import { selectCurrentUser } from "Redux/User/user-selectors";
import { clearCategorie } from "Redux/Categories/categories-actions";
import { logout } from "Redux/User/user-actions";

//react router dom
import { useHistory } from "react-router";

const Organization = ({
  setOrganizationModule,
  setOrganizationSeleted,
  currentUser,
  cssStyle,
  organizationId,
  clearCategorie,
  clearOrganization,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (organizationId) {
      clearCategorie();
      clearOrganization();
    }
  }, []);

  //Aca se realizara toda la logica de seleccion de organizacion
  const handledSelectOrganizacion = (organization) => {
    const rolesModules =
      Array.isArray(organization.availableModules) &&
      organization.availableModules.map(
        (orgnizationModule) => orgnizationModule.module
      );

    if (rolesModules !== 0) {
      setLoading(true);
      setOrganizationModule(organization);
      setOrganizationSeleted(organization);
      setLoading(false);

      history.push(ROUTES.HOME);
    } else {
      history.push(ROUTES.ORGANIZATION);
    }
  };

  const { organizations } = currentUser;
  return (
    <>
      {loading && <Spinner />}
      <div
        className="flex justify-center h-screen items-center w-screen"
        style={{ backgroundColor: cssStyle.backgroundColor }}
      >
        <div
          className="flex flex-col max-md:w-full max-md:h-full max-md:p-4 max-md:rounded-none md:w-auto md:rounded-lg"
          style={{ backgroundColor: cssStyle.backgroundBox }}
        >
          <div className="flex flex-col gap-3 w-full text-center justify-between">
            <img
              className="w-44 m-3 max-md:self-center"
              src={cssStyle.companyLogo}
              alt="logo"
            />
            <p
              className="max-md:mr-4"
              style={{
                color: cssStyle.TextColorCompany,
                fontSize: cssStyle.fontSizeCompanyTittle,
                fontWeight: "700",
                justifySelf: "center",
              }}
            >
              {cssStyle.textCompany}
            </p>
          </div>
          <div className="flex justify-center max-md:flex-col my-10 mx-2">
            {Array.isArray(organizations) &&
              _.orderBy(organizations, ["id"], ["asc"]).map(
                (organization, index) => (
                  <button
                    className="bg-transparent border-none"
                    key={index}
                    style={{
                      outline: "inherit",
                    }}
                    onClick={() => handledSelectOrganizacion(organization)}
                  >
                    <div className="flex justify-center items-start cursor-pointer lg:w-44 lg:h-44 lg:m-3">
                      <div
                        className="flex justify-center items-center h-32"
                        style={{
                          background:
                            organization.cssStyle.backgroundColorBoxOrg,
                        }}
                      >
                        <img
                          className="lg:w-44"
                          src={organization.cssStyle.backgroundImage}
                          alt="logo organization"
                        />
                      </div>
                    </div>
                  </button>
                )
              )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  Organization: selectOrganizacion(state),
  currentUser: selectCurrentUser(state),
  cssStyle: selectCompanyStyle(state),
  organizationId: selectedOrganizationId(state),
});

const mapDispatchToProps = (dispatch) => ({
  setOrganizationModule: (organizationSelect) =>
    dispatch(setModuleOrganization(organizationSelect)),
  setOrganizationSeleted: (organization) =>
    dispatch(setOrganizationSeleted(organization)),
  clearCategorie: () => dispatch(clearCategorie()),
  clearOrganization: () => dispatch(clearOrganization()),
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
