import tw from "tailwind-styled-components";

const Label = tw.span`
      inline
      font-semibold
      bg-transparent
      no-underline	
      text-base	
      leading-5	
      ${(props) =>
        props.$InputTittle && `m-y mx-1.5 text-gray-900 text-primary-title`}
      ${(props) =>
        props.$DropDownMenu &&
        `m-y mx-1.5 text-primary-header-menu cursor-pointer hover:text-hover-header-menu`}
      ${(props) =>
        props.$DropDownUserName &&
        `text-primary-header-menu cursor-pointer mr-1 flex`}  
      ${(props) =>
        props.$DropDownNavMenu &&
        `m-y mx-1.5 text-gray-900 cursor-pointer hover:text-hover-header-menu`}
`;

export default Label;
