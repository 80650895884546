import React from "react";
import ConfigUser from "Components/configUser/configUser";

const EditProfile = ({ mobileView }) => {
  return (
    <div className="w-screen">
      <ConfigUser mobileView={mobileView} />
    </div>
  );
};

export default EditProfile;
