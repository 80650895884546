//Services
import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL } from "Config/config";

const categoriesBase = `${API_URL}`;

export async function fetchCategories(
  token,
  { organizationId, size = 5, categoriesLastRow = 0, resourceLimit = 10 }
) {
  if (categoriesLastRow !== 0) {
    size = size + categoriesLastRow;
  }
  const headers = {
    Authorization: token,
  };
  return FetchPlus(
    `${categoriesBase}organizations/${organizationId}/categories?size=${size}&resourceLimit=${resourceLimit}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((err) => {
      if (err === 401) {
        return err;
      }
      console.log(err);
    });
}

export async function fetchmoreResourceCategory(
  token,
  { organizationId, categoryId, resourcesLastRow = 0, size = 10 }
) {
  const headers = {
    Authorization: token,
  };
  if (resourcesLastRow !== 0) {
    size = size + resourcesLastRow;
  }
  return FetchPlus(
    `${categoriesBase}organizations/${organizationId}/categories/${categoryId}/resources?page=0&size=${size}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((err) => {
      console.log(err);
    });
}

// get recommend for you resource
export async function fetchRecommendDataresource(
  token,
  { organizationId, page = 0, size = 10 }
) {
  const headers = {
    Authorization: token,
  };
  return FetchPlus(
    `${categoriesBase}organizations/${organizationId}/user/recommended?page=${page}&size=${size}`,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((err) => {
      console.log(err);
    });
}

//this endpoint aims to send the views of each resource
export async function fetchResourceMoreViewsCount(
  token,
  { organizationId, resourceId }
) {
  const data = {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return FetchPlus(
    `${categoriesBase}organizations/${organizationId}/resources/${resourceId}/view`,
    data
  )
    .then((response) => response)
    .catch((err) => {
      console.log(err);
    });
}

// this endpoint gets more view of the resource on the platform
export async function fetchGetMoreViewResource(
  token,
  { organizationId, page = 0, size = 10 }
) {
  const headers = {
    Authorization: token,
  };

  return FetchPlus(
    `${categoriesBase}organizations/${organizationId}/resources/views/retrieve?page=${page}&size=${size}`,
    {
      headers,
    }
  )
    .then((data) => data)
    .catch((err) => {
      console.log(err);
    });
}
