import CategoryActionTypes from "./categories-types";
import UserActionTypes from "../User/user-types";

const INITIAL_STATE = {
  //Categories
  categories: [],
  isFetching: true,
  totalCategoryCount: null,
  //Videos
  isMoreAdditionalVideosFetching: {},
  //resources
  totalResourceItem: null,
  totalResourceRecItem: null,
  currentMoreVieRecourcePage: null,
  totalResourceMoreVieItem: null
};

const categoriesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    //Categories
    case CategoryActionTypes.SET_CATEGORY_DATA: {
      const categories = payload.categoriesData.rows;
      const filteredData = Array.isArray(categories)
        ? categories.filter(
          (category) =>
            state.categories.findIndex((cat) => category.id === cat.id) === -1
        )
        : [];
      return {
        ...state,
        categories: [...state.categories, ...filteredData],
        totalCategoryCount: payload.categoriesData.count,
        isFetching: false,
      };
    }
    case CategoryActionTypes.SET_LOADING_CATEGORY_DATA: {
      return {
        ...state,
        isFetching: payload,
      };
    }

    case CategoryActionTypes.SET_LOADING_MORE_ADDITIONAL_VIDEOS: {
      const { categoryId, loading } = payload;

      return {
        ...state,
        isMoreAdditionalVideosFetching: {
          ...state.isMoreAdditionalVideosFetching,
          [categoryId]: loading,
        },
      };
    }
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        categories: [],
        totalCategoryCount: null,
        totalResourceRecItem: null,
      };
    case CategoryActionTypes.CLEAR_CATEGORIES:
      return {
        ...state,
        categories: [],
        totalCategoryCount: null,
        totalResourceRecItem: null,
      };
    //get recommend resource item
    case CategoryActionTypes.GET_RECOMMEND_RESOURCE_ITEM:
      const oldCategories = state.categories;
      const ResourcesRecommend = payload.resources.rows;

      // this method filter recommend categories
      const filterRecommendCategories = Array.isArray(oldCategories)
        ? oldCategories.filter((categories) => {
          return categories.isRecommended === true;
        })
        : [];
      filterRecommendCategories[0].resources.push(...ResourcesRecommend);

      oldCategories.push(filterRecommendCategories[0]);

      // her eliminate the equal resource
      let filterCategorie = oldCategories.reduce((unique, o) => {
        if (!unique.some((categories) => categories.name === o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      return {
        ...state,
        categories: filterCategorie,
        currentRecommendRecourcePage: payload.pagination.currentPage,
        totalResourceRecItem: payload.resources.count,
      };

    case CategoryActionTypes.SET_LOADING_MORE_RESOURCE:
      const { categoryId, resources } = payload;
      const newsResources = resources.resources.rows;
      const totalResourceItem = resources.pagination.totalItems;

      const newCategories = Array.prototype.concat.apply([], state.categories);

      const idx = newCategories.findIndex(
        (category) => category.id === categoryId
      );
      if (idx !== -1) {
        newCategories[idx].resources = Array.prototype.concat.apply(
          newCategories[idx].resources,
          newsResources
        );
        newCategories[idx].resources = newCategories[idx].resources.reduce(
          (unique, o) => {
            if (!unique.some((resource) => resource.id === o.id)) {
              unique.push(o);
            }
            return unique;
          },
          []
        );
      }
      return {
        ...state,
        categories: newCategories,
        totalResourceItem: totalResourceItem,
        isMoreAdditionalVideosFetching: {
          ...state.isMoreAdditionalVideosFetching,
          [categoryId]: false,
        },
      };

    //This case extracts and inserts the most viewed resources
    case CategoryActionTypes.GET_MORE_VIEW_RESOURCE:
      const stageCategorie = state.categories;
      const moreViewResource = payload.resources.rows;

      const filterMoreViewCategory = Array.isArray(stageCategorie)
        ? stageCategorie.filter((categories) => {
          return categories?.settings?.isMostView === true;
        })
        : [];

      filterMoreViewCategory[0].resources.push(...moreViewResource);
      stageCategorie.push(filterMoreViewCategory[0]);

      return {
        ...state,
        currentMoreVieRecourcePage: payload.pagination.currentPage,
        totalResourceMoreVieItem: payload.resources.count,
      }

    default:
      return state;
  }
};

export default categoriesReducer;
