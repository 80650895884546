import React from "react";
import { Link } from "Components";
import Label from "./Label";

export const SubMenuItem = ({ SubMenuItem, currentRoute }) => {
  return (
    <div>
      <ul className="w-auto border-none no-underline">
        {Array.isArray(SubMenuItem) &&
          SubMenuItem.map(({ subModule }, index) => (
            <li
              className={`text-primary-dropdown-link hover:text-hover-dropdown-link 
               py-2.5 text-sm font-semibold
               ${
                 currentRoute === subModule.path
                   ? "bg-active-dropdown-link"
                   : "text-primary-header-menu"
               }`}
              key={index}
            >
              {subModule.path.includes("#") ? (
                <Label
                  $DropDownMenu
                  onClick={() => window.location.replace(subModule.path)}
                >
                  {subModule.name}
                </Label>
              ) : (
                <Link
                  className={`${
                    currentRoute === subModule.path
                      ? "text-active-dropdown-link"
                      : "text-primary-header-menu"
                  } hover:text-hover-dropdown-link  mx-1.5`}
                  to={subModule.path}
                >
                  {subModule.name}
                </Link>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};
