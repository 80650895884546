import React from "react";
import "./FormInput.css";

const FormInput = (props) => {
  const { onChange, label, seeMoreLabel, helpText, ...rest } = props;

  return (
    <>
      <div className="group">
        <input
          className="bg-primary-input form-input"
          onChange={onChange}
          {...rest}
        />

        {label ? (
          <label
            className={`${
              props?.value?.length ? "shrink" : ""
            } form-input-label ${seeMoreLabel ? "see-more-label" : ""}`}
          >
            {label}
          </label>
        ) : null}
      </div>
      {helpText && <div className="text-center font-italic">{helpText}</div>}
    </>
  );
};

export default FormInput;
