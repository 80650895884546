import typeOrganization from "./organization-type";

const INITIAL_STATE = {
  id: null,
  name: null,
  cssStyle: [],
  countryId: null,
  companyId: null,
  isFetching: true,
};

const OrganizationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case typeOrganization.SET_ORGANIZATION_SELECTED:
      const {
        id,
        name,
        cssStyle,
        countryId,
        companyId,
        permissions,
        membership,
      } = action.payload;

      return {
        ...state,
        isFetching: false,
        id,
        name,
        cssStyle,
        countryId,
        companyId,
        permissions,
        membership,
      };
    case typeOrganization.CLEAR_ORGANIZATION:
      return {
        id: null,
        name: null,
        cssStyle: [],
        countryId: null,
        companyId: null,
      };

    default:
      return state;
  }
};
export default OrganizationReducer;
