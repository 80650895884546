//Libs
import React from "react";
import tw from "tailwind-styled-components";

const StyledLabel = tw.label`
  text-primary-labelError
  my-0
  mx-2.5
  py-0
  px-2.5
  text-center
`;

export default function ErrorLabel(props) {
  const { value } = props;

  return value ? <StyledLabel {...props}>{value}</StyledLabel> : null;
}
