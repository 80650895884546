import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL } from "Config/config";

const newsBase = `${API_URL}organizations`;

//Cambiar aycn
export const fetchAllCardiOneNews = (
  token,
  { usersLimit = 10, usersLastRow = 0, organizationId } = {}
) => {
  const headers = {
    Authorization: token,
  };
  return FetchPlus(
    `${newsBase}/${organizationId}/news?page=${usersLastRow}&size=${usersLimit}`,
    { headers }
  )
    .then((response) => response || [])
    .catch((err) => {
      if (err === 401) {
        return 401;
      }
      console.log(err);
    });
};
