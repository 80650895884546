import React from "react";
import { CgSpinner } from "react-icons/cg";
//style
import spinnerStyle from "Components/Spinner/spinnerStyle.module.css";

const SpinnerCategory = () => {
  return (
    <div className="w-full h-auto flex justify-center text-red-600 mb-12 text-6xl">
      <CgSpinner className={`${spinnerStyle.animateSpin}`} />
    </div>
  );
};

export default SpinnerCategory;
