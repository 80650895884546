import React from "react";
import { ROUTES } from "Config/config";
import { Link, useHistory } from "react-router-dom";
//component
import logoAsocar from "Assets/logo-asocar.png";
import { FiArrowDown } from "react-icons/fi";
//redux
import { connect } from "react-redux";
import { selectQuestResult } from "Redux/Questions/question-selector";

const ResultPage = ({ result, mobileView }) => {
  const history = useHistory();

  return !result ? (
    <div
      className="w-screen min-h-screen bg-sign-in-background bg-cover
bg-center flex justify-center md:pt-28 md:pb-14"
    >
      <div className="flex flex-col glass w-full md:w-5/12 md:h-vh-22 p-10 md:rounded-2xl">
        <img
          alt="Logo ASOCAR"
          className="w-vw-7 max-md:w-56 self-center m-3"
          src={logoAsocar}
        />
        <span className="text-result-title text-3xl text-center font-semibold">
          Actualmente no tiene ningún resultado que mostrar
        </span>
        <button
          className={`btn my-2 w-full glass`}
          onClick={() => history.push(ROUTES.SIGNIN)}
        >
          😃 Volver al inicio
        </button>
      </div>
    </div>
  ) : (
    <div className="w-screen min-h-screen bg-white flex flex-col justify-center md:pb-14 border-b-12 border-bg-result">
      {mobileView ? (
        <div className="flex justify-center h-auto bg-header-result py-1">
          <img
            alt="Logo ASOCAR"
            className="w-vw-4 max-md:w-1/4 self-center m-3"
            src={logoAsocar}
          />
        </div>
      ) : (
        <div
          className="flex justify-center h-28 bg-header-bg-box bg-cover
    bg-center py-1"
        />
      )}
      <div className="flex justify-center flex-col w-full lg:px-52">
        <div className="w-full p-2 lg:p-1 flex flex-col gap-2 max-md:flex-wrap">
          <div className="mt-4">
            <span className="text-result-title text-5xl mb-3 lg:my-6 text-left font-bold">
              Estimado empresario
            </span>
            <p className="text-base lg:text-lg text-justify font-semibold">
              Un gusto saludarlo, permitamos presentarle a su consideración el
              Informe de su Autoevaluación de su Empresa Familiar. Este
              documento le permitirá descubrir por usted mismo, que tan
              encaminado se encuentra usted, su familia y su empresa para
              continuar a través de las generaciones.
              <br />
              <br /> Además, se le van a indicar las áreas donde debería
              vocalizarse más para mejorar en el futuro. <br />
              <br />
              Nuestro compromiso siempre está dirigido a prestar servicios de
              alto valor agregado, con la intención de llegar a ser aliado
              estratégico suyo y de su familia, en su esfuerzo por mantener
              unida a su familia en entorno a una empresa exitosa y un
              patrimonio duradero.
              <br />
              <br />
              Agradecemos su atención al documento, y nos ponemos a su
              disposición para cualquier apoyo que usted necesite en este reto
              de la continuidad. <br />
              <br /> Sinceramente,
            </p>
            <img alt="signature" />
            <p className="text-base lg:text-lg text-justify font-semibold">
              Arlette Urrea Consultora
            </p>
          </div>
          <div
            onClick={() => {
              window.location.replace("#strength");
            }}
            className="flex justify-center items-center h-10 lg:h-20 w-10 lg:w-20 bg-primary-button 
            hover:bg-primary-hover-button text-white text-3xl lg:text-5xl rounded-full self-center animate-bounce"
          >
            <FiArrowDown />
          </div>
          <div
            className="flex flex-col text-black w-full md:rounded-3xl lg:shadow-lg border-2
           border-gray-50 bg-white max-md:my-2 lg:my-12 lg:p-3 p-2"
          >
            <span className="text-result-title text-5xl mb-3 lg:mx-6 lg:my-6 text-left font-bold">
              Fortalezas
            </span>
            <div className="flex flex-col lg:py-4">
              {Array.isArray(result.rightAnswers) &&
                result.rightAnswers.map(({ feedback, resources }, index) => (
                  <React.Fragment key={index}>
                    <span className="text-justify lg:text-base font-medium lg:pl-6 pb-2">
                      {index + 1}) {feedback}
                    </span>
                    <span className="text-justify text-base font-medium lg:pl-6 pb-2">
                      <span className="text-lg font-semibold">
                        Contenido recomendado:
                      </span>
                      {Array.isArray(resources) &&
                        resources.map(({ name }, indexY) => (
                          <li className="pl-2 lg:pl-4" key={indexY}>
                            {name}
                          </li>
                        ))}
                    </span>
                  </React.Fragment>
                ))}
            </div>
          </div>
          <div
            id="strength"
            className="flex flex-col text-black w-full md:rounded-3xl lg:shadow-lg border-2
           border-gray-50 bg-white max-md:my-2 lg:mb-12 lg:p-3 p-2"
          >
            <span className="text-result-title text-5xl mb-3 lg:mx-6 lg:my-6 text-left font-bold">
              Debilidades
            </span>
            <div className="flex flex-col lg:py-4">
              {Array.isArray(result.wrongAnswers) &&
                result.wrongAnswers.map(({ feedback }, index) => (
                  <React.Fragment key={index}>
                    <span className="text-justify text-base font-medium pl-6 mb-3">
                      {index + 1}) {feedback}
                    </span>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
        <a
          className={`btn my-2 w-full bg-primary-button hover:bg-primary-hover-button rounded-none border-none lg:rounded-md`}
          href="/#"
          download="Resultado de questionario"
        >
          🙂 Descargar resultados
        </a>
        <Link
          to={ROUTES.SIGNIN}
          className="text-result-title hover:text-hover-link no-underline text-center text-lg"
        >
          ¿Volver al inicio?
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  result: selectQuestResult(state),
});

export default connect(mapStateToProps, null)(ResultPage);
