import styled from "styled-components";

const itemNoPathP = styled.span`
  margin: ${(props) => props.margin || props.theme.link.margin};
  color: ${(props) => props.color || props.theme.link.color};
  font-weight: 550;
  letter-spacing: 0.2px;
  &:hover {
    color: #633d8c;
  }
`;
export default itemNoPathP;
