import RoomActionTypes from "./room-types";
import VideoActionTypes from "Redux/Video/video-types";
import UserActionTypes from "Redux/User/user-types";
import { fetchRooms, fetchRoomData } from "../../Services/RoomService";

export function getRooms(token, whereOpts = {}) {
  return (dispatch) => {
    if (!token) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido para obtener las salas",
        },
      });
      return;
    }

    dispatch({
      type: RoomActionTypes.SET_LOADING_ROOM_DATA,
      payload: true,
    });

    fetchRooms(token, whereOpts)
      .then((rooms) => {
        const newArray = Array.prototype.concat.apply([], rooms);
        dispatch({
          type: RoomActionTypes.SET_ROOM_DATA,
          payload: newArray,
        });
      })
      .catch((err) => console.log(err));
  };
}

export function getRoomData(token, roomId, whereOpts = {}) {
  return (dispatch) => {
    if (!token || !roomId) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido para obtener las salas",
        },
      });
      return;
    }

    return fetchRoomData(token, roomId, whereOpts)
      .then((room) => {
        if (!room) {
          return false;
        }
        dispatch({
          type: VideoActionTypes.REFRESH_ROOM_WATCH_PROPERTIES,
          payload: room,
        });

        return true;
      })
      .catch((err) => console.log(err));
  };
}

export const getLastUserMeet = (data) => ({
  type: RoomActionTypes.GET_LAST_USERS_MEET,
  payload: data,
});

export const resetRooms = () => ({
  type: RoomActionTypes.RESET,
});
