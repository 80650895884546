import React from "react";
import Label from "Components/Header/Label";
//library
import { BiExit } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
//react router
import { Link } from "Components";
import { useHistory } from "react-router-dom";
import { ROUTES } from "Config/config";
//service
import { CompanyService } from "Services/CompanyService";
//selector
import { connect } from "react-redux";
import {
  selectedOrganizationId,
  selectedOrganizationStyle,
} from "Redux/Organization/organization-select";
import { selectCurrentUser } from "Redux/User/user-selectors";
//action
import {
  setModuleOrganization,
  orgnizacionUnseleted,
  dccLogout,
} from "Redux/DynamicConfigCompany/dcc-action";
import { changeModalUserInfoState } from "Redux/User/user-actions";
import { clearAssitant } from "Redux/ContactUs/assistant-actions";
import { clearDirectory } from "Redux/Directory/directory.actions";
import { clearSpeaker } from "Redux/Speakers/speaker-actions";
import { clearNews } from "Redux/newsCardiOne/new-cardi-one-actions";
import { clearCategorie } from "Redux/Categories/categories-actions";
import {
  clearOrganization,
  setOrganizationSeleted,
} from "Redux/Organization/organizarion-action";

const handleRedirectOrganization = async ({
  organization,
  unselected,
  clearCategorie,
  clearAssitant,
  clearDirectory,
  clearSpeaker,
  clearNews,
  setOrganizationModule,
  history,
  setOrganizationSeleted,
}) => {
  const companyData = await CompanyService.getCompanyData(
    window.location.host
  ).then((data) => data);

  if (companyData?.company) {
    unselected(companyData.company);
    clearCategorie();
    clearOrganization();
    clearAssitant();
    clearDirectory();
    clearSpeaker();
    clearNews();
    const rolesModules =
      Array.isArray(organization.availableModules) &&
      organization.availableModules.map(
        (orgnizationModule) => orgnizationModule.module
      );

    if (rolesModules !== 0) {
      setOrganizationModule(organization);
      setOrganizationSeleted(organization);

      history.push(ROUTES.HOME);
    }
  }
};

const SwitchProfile = ({
  roles,
  logout,
  dccLogoutCompany,
  clearOrganization,
  unselected,
  currentUser,
  setOrganizationModule,
  setOrganizationSeleted,
  organizationId,
  mobileView,
  setNavMenuOpen,
  clearAssitant,
  clearDirectory,
  clearSpeaker,
  clearNews,
  clearCategorie,
  setDropDownShow,
}) => {
  const history = useHistory();

  return (
    <ul
      className="w-44 text-base list-none rounded float-left absolute bg-primary-dropdown ring-1 ring-black
    ring-opacity-5 focus:outline-none border-t-4 border-solid border-top-dropdown pointer-events-auto z-50 px-2"
      onMouseLeave={() => setDropDownShow(false)}
      onBlur={() => setDropDownShow(false)}
    >
      {Array.isArray(roles) &&
        roles.map(({ roleName, iconUrl }, index) => (
          <li key={index}>
            <a
              href="."
              className={`pl-4 text-primary-dropdown-link hover:bg-hover-dropdown-link 
               py-2.5 text-sm font-semibold`}
            >
              <img
                alt=""
                src={iconUrl}
                width="40px"
                circular="10%"
                style={{ marginRight: "24px" }}
              />
              {roleName}
            </a>
          </li>
        ))}
      <li
        className={`xl:pl-4 text-primary-dropdown-link hover:bg-hover-dropdown-link 
               py-2.5 font-semibold cursor-pointer text-base px-2`}
      >
        <Link
          className="text-primary-dropdown-link hover:text-primary-dropdown-link flex"
          to={ROUTES.EDIT_PROFILE}
          onClick={() => {
            if (mobileView) {
              setNavMenuOpen(false);
            }
            setDropDownShow(false);
          }}
        >
          Cuenta <FiUser className="mx-1.5" />
        </Link>
      </li>
      <>
        {currentUser?.organizations.map(
          (organization, index) =>
            organization.id !== organizationId && (
              <li
                className={`xl:pl-4 text-primary-dropdown-link hover:bg-hover-dropdown-link 
                py-2.5 font-semibold cursor-pointer text-base px-2`}
                key={index}
                onClick={async () => {
                  if (mobileView) {
                    setNavMenuOpen(false);
                  }
                  await handleRedirectOrganization({
                    organization,
                    unselected,
                    clearCategorie,
                    clearAssitant,
                    clearDirectory,
                    clearSpeaker,
                    clearNews,
                    setOrganizationModule,
                    history,
                    setOrganizationSeleted,
                  });
                  setDropDownShow(false);
                }}
              >
                <img alt="logo" src={organization.cssStyle?.backgroundImage} />
              </li>
            )
        )}
      </>
      {!mobileView && (
        <li
          className={`xl:pl-4 text-primary-dropdown-link hover:bg-hover-dropdown-link hover:text-primary-dropdown-link
        py-2.5 text-sm font-semibold`}
        >
          <Label
            $DropDownUserName
            onClick={async () => {
              const companyData = await CompanyService.getCompanyData(
                window.location.host
              ).then((data) => data);
              unselected(companyData.company);
              clearOrganization();
              history.push(ROUTES.SIGNIN);
              dccLogoutCompany();
              logout();
            }}
          >
            Cerrar sesión <BiExit className="mx-1.5" />
          </Label>
        </li>
      )}
    </ul>
  );
};
const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  organizationId: selectedOrganizationId(state),
  organzationStyle: selectedOrganizationStyle(state),
});
const mapDispatchToProps = (dispatch) => ({
  clearOrganization: () => dispatch(clearOrganization()),
  unselected: (company) => dispatch(orgnizacionUnseleted(company)),
  changeStateModal: (state) => dispatch(changeModalUserInfoState(state)),
  dccLogoutCompany: () => dispatch(dccLogout()),
  setOrganizationModule: (organizationSelect) =>
    dispatch(setModuleOrganization(organizationSelect)),
  setOrganizationSeleted: (organization) =>
    dispatch(setOrganizationSeleted(organization)),
  clearAssitant: () => dispatch(clearAssitant()),
  clearDirectory: () => dispatch(clearDirectory()),
  clearSpeaker: () => dispatch(clearSpeaker()),
  clearNews: () => dispatch(clearNews()),
  clearCategorie: () => dispatch(clearCategorie()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SwitchProfile));
