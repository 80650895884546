import { createSelector } from "reselect";

const selectDCC = (state) => state.dcc;

//select organizacion
export const selectOrganizacion = createSelector(
  [selectDCC],
  (dcc) => dcc.organizations
);
//select cssStyle company
export const selectCssStyleCompany = createSelector(
  [selectDCC],
  (dcc) => dcc.cssStyle
);
//select company modules
export const selectCompanyModule = createSelector(
  [selectDCC],
  (dcc) => dcc.modules
);

//select company fitshing
export const selectFetching = createSelector(
  [selectDCC],
  (dcc) => dcc.isFetching
);
//select all company atribute
export const selectCompany = createSelector([selectDCC], (dcc) => dcc);
//get submodulos
export const selectSubModulos = createSelector(
  [selectDCC],
  (dcc) => dcc.subModules
);
//company id
export const selectCompanyId = createSelector([selectDCC], (dcc) => dcc.id);
//company Style
export const selectCompanyStyle = createSelector(
  [selectDCC],
  (dcc) => dcc.styleCompany
);
