import React, { useState } from "react";
//library
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { FiAlertCircle } from "react-icons/fi";
//component
import { SpinnerButton } from "Components";
//redux
import { connect } from "react-redux";
//action
import {
  setQuestionData,
  setQuizCodeRedux,
} from "Redux/Questions/questions-action";
//selector
import { selectCompanyId } from "Redux/DynamicConfigCompany/dcc-select";
import { ROUTES } from "Config/config";
//service
import { setQuizCode } from "Services/QuestionQuizService";

const LadingCode = ({ setQuestionData, companyId, setQuizCodeRedux }) => {
  const history = useHistory();

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    code: yup.string().required("El codigo es requerido."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    //THIS IS FUNCTION SEND CODE
    const { code } = data;
    setLoading(true);
    const statud = await setQuizCode({ code, companyId }).then(
      (response) => response
    );

    const response = await setQuizCode({ code, companyId }).then((response) =>
      response.json()
    );
    setQuestionData(response);
    setLoading(false);

    if (statud.status === 200) {
      setQuizCodeRedux(code);
      history.push(ROUTES.QUIZ_PAGE);
    } else {
      setMessage("El codigo no es valido, intente de nuevo");
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    }
  };

  return (
    <div
      className="w-screen h-screen bg-sign-in-background 0 bg-cover
    bg-center flex justify-center items-center"
    >
      <div className="w-full h-full md:w-vw-8 md:h-auto md:py-4 glass md:rounded-lg flex flex-col justify-center items-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full px-3 mb-2 lg:px-0 lg:w-8.5/12 flex flex-col justify-center text-center"
        >
          <span className="text-primary-label-input text-2xl font-primary-font-family mb-3">
            Ingrese el codigo
          </span>
          <input
            {...register("code")}
            className="bg-primary-input-user-config text-primary-input
                   py-1 pl-3 pr-2 mb-2 border-none rounded-sm text-sm lg:text-3xl lg:font-bold block focus:bg-gray-200 focus:border-2 focus:border-blue-600"
          />
          <button
            type="submit"
            className={`btn bg-primary-button border-none text-primary-button 
            hover:bg-primary-hover-button font-bold py-2 px-4 rounded`}
          >
            {loading && <SpinnerButton />}
            Ingresar
          </button>
        </form>
        {message && (
          <div class="alert alert-error py-2">
            <div class="flex items-center justify-center ">
              <FiAlertCircle className="w-6 h-6 mx-2 stroke-current" />
              <span>{message}</span>
            </div>
          </div>
        )}
        {errors?.code && (
          <div class="alert alert-error py-2">
            <div class="flex items-center justify-center ">
              <FiAlertCircle className="w-6 h-6 mx-2 stroke-current" />
              <span>{errors.code.message}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapToPropsDispatch = (dispatch) => ({
  setQuestionData: (questions) => dispatch(setQuestionData(questions)),
  setQuizCodeRedux: (code) => dispatch(setQuizCodeRedux(code)),
});
const mapToPropsState = (state) => ({
  companyId: selectCompanyId(state),
});

export default connect(
  mapToPropsState,
  mapToPropsDispatch
)(React.memo(LadingCode));
