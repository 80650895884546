import AssigmentsType from "./Assigments-types";

const INITIAL_STATE = {
  myAssigments: null,
};
const assigmentsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AssigmentsType.SET_CURRENT_ASSIGMENTS:
      return {
        ...state,
        myAssigments: payload,
      };
    case AssigmentsType.RESET_MY_ASSIGMENTS:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default assigmentsReducer;
