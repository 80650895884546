//Libs
import React from "react";
import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";

//Config
import { ROUTES } from "Config/config";

const StyledContainer = tw.div`w-full flex justify-center`;
const StyledQuestion = tw.span` text-white text-center mr-1 flexjustify-center`;
const StyledLink = tw(Link)`text-white no-underline hover:text-hover-link`;

export default function GoToSignin() {
  return (
    <StyledContainer>
      <StyledQuestion>¿Ya tiene cuenta?</StyledQuestion>
      <StyledLink to={ROUTES.SIGNIN}>Ingresar.</StyledLink>
    </StyledContainer>
  );
}
