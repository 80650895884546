import { createSelector } from "reselect";

const selectVideo = (state) => state.video;

//Messages
export const selectMessages = createSelector(
  [selectVideo],
  (video) => video.messages
);

//Polls Select
export const selectPolls = createSelector(
  [selectVideo],
  (video) => video.polls
);

export const selectPollAnswers = createSelector(
  [selectVideo],
  (video) => video.pollAnswers
);

export const selectMessagesLoaded = createSelector(
  [selectVideo],
  (video) => video.messagesLoaded
);

export const selectCurrentVideoId = createSelector(
  [selectVideo],
  (video) => video.videoId
);

export const selectCurrentVideoUrl = createSelector(
  [selectVideo],
  (video) => video.videoUrl
);

export const selectCurrentVideoStreamId = createSelector(
  [selectVideo],
  (video) => video.streamId
);

export const selectCurrentVideoStreamServer = createSelector(
  [selectVideo],
  (video) => video.streamWss
);

export const selectCurrentWithChat = createSelector(
  [selectVideo],
  (video) => video.withChat
);

export const selectCheckUserAlreadyVote = createSelector(
  [selectVideo],
  (video) => video.userSentAnswer
);

export const selectVideoSettings = createSelector(
  [selectVideo],
  (video) => video.settings
);

export const selectIsAvailableByMembership = createSelector(
  [selectVideo],
  (video) => video.isAvailableByMembership
);

export const selectZoomIsOpen = createSelector(
  [selectVideo],
  (video) => video.zoomStart
);
