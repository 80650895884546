import RoomActionTypes from "./room-types";

const INITIAL_STATE = {
  isFetching: false,
  rooms: [],
  roomCast: [],
  roomVideos: "",
  zoomLastCurrentUser: null,
};

const roomsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RoomActionTypes.SET_ROOM_DATA: {
      //temporalcode
      // const zoomRoom = payload.find((value) => value.name === "PolpoFlix Zoom");
      // zoomRoom.setting = { source: "videoChatZoom" };

      return {
        ...state,
        rooms: Array.prototype.concat.apply(state.rooms, payload),
        isFetching: false,
      };
    }
    case RoomActionTypes.SET_LOADING_ROOM_DATA: {
      return {
        ...state,
        isFetching: payload,
      };
    }
    case RoomActionTypes.GET_LAST_USERS_MEET: {
      return {
        ...state,
        zoomLastCurrentUser: payload,
      };
    }
    case RoomActionTypes.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default roomsReducer;
