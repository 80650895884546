/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
//component
import { SpinnerButton, AssigmentCard, AssigmentStatud } from "Components";
import { ImWarning } from "react-icons/im";
import { ROUTES } from "Config/config";
//service
import {
  getAssigmentSpecificResource,
  setAllAssigment,
} from "Services/AssigmentService";
//redux
import { connect } from "react-redux";
//action
import { setMyAssigment } from "Redux/Assigments/Assigments-action";
//select
import { selectMyAssigments } from "Redux/Assigments/Assigments-selectors";
import { selectCurrentUser } from "Redux/User/user-selectors";
import { selectedOrganizationId } from "Redux/Organization/organization-select";

const AssigmentPage = ({
  myAssigments,
  currentUser,
  organizationId,
  setMyAssigment,
}) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [allAssigmentInfo, setAllAssigmentInfo] = useState({
    msg: null,
    errorMsg: null,
    succeMsg: null,
    loading: false,
  });

  useEffect(() => {
    const getUpdateHomeWork = async () => {
      const responseData = {
        status: null,
        fetchData: null,
      };

      const location = window.location.pathname;
      const resourceId = location.split("/")[3];
      const { token } = currentUser;

      await getAssigmentSpecificResource({
        resourceId,
        token,
        organizationId,
      })
        .then((data) => {
          responseData.status = data.status;

          return data.json();
        })
        .then((reponse) => (responseData.fetchData = reponse.homeworks));

      if (responseData.status === 200) {
        setMyAssigment(responseData.fetchData);
      } else {
        history.push(ROUTES.HOME);
      }
    };

    getUpdateHomeWork();
  }, []);

  return (
    <>
      <div
        className="flex flex-col lg:flex-row items-center w-screen min-h-screen bg-sign-in-background bg-cover
  bg-center max-md:py-3 md:py-20 md:px-20 gap-4"
      >
        {/* MODAL SHOW SEND OR NOT SEND */}
        {showModal && (
          <div
            className="fixed z-10 inset-0 overflow-y-auto ease-in duration-200"
            ariaLabelledby="modal-title"
            role="dialog"
            ariaModal="true"
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              ></span>
              <div className="inline-block justify-center align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="w-full flex justify-center h-auto p-4">
                  <div className="flex flex-col">
                    <ImWarning className="text-9xl self-center m-4 text-yellow-400" />
                    <span
                      style={{ textAlign: "center" }}
                      className="self-center text-black text-2xl"
                    >
                      {allAssigmentInfo.msg}
                    </span>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    className={`py-3 px-4 h-12 text-2xl font-bold rounded-sm mx-2 w-full inline-flex justify-center border-gray-300 shadow-sm 
                    bg-primary-button text-primary-button hover:bg-primary-hover-button sm:w-auto sm:text-sm`}
                    onClick={async () => {
                      const location = window.location.pathname;
                      const resourceId = location.split("/")[3];

                      const responseData = {
                        status: null,
                        fetchData: null,
                      };

                      const { token } = currentUser;
                      setAllAssigmentInfo({
                        ...allAssigmentInfo,
                        loading: true,
                      });

                      await setAllAssigment({
                        resourceId,
                        organizationId,
                        token,
                        confirmation: true,
                      })
                        .then((response) => {
                          responseData.status = response.status;
                          return response.json();
                        })
                        .then(
                          (data) => (responseData.fetchData = data.message)
                        );

                      setAllAssigmentInfo({
                        ...allAssigmentInfo,
                        loading: false,
                      });

                      const currentAssigmentData =
                        await getAssigmentSpecificResource({
                          resourceId,
                          token,
                          organizationId,
                        }).then((response) => response.json());

                      if (currentAssigmentData.homeworks) {
                        setAllAssigmentInfo({
                          succeMsg:
                            "Todas sus tareas han sido enviadas con éxito.",
                        });

                        setTimeout(() => {
                          setAllAssigmentInfo({
                            succeMsg: null,
                          });
                        }, 5000);

                        setMyAssigment(currentAssigmentData.homeworks);
                        setShowModal(false);
                      }
                    }}
                    disabled={allAssigmentInfo.loading ? true : false}
                  >
                    {allAssigmentInfo.loading && <SpinnerButton />}
                    Aceptar
                  </button>
                  <button
                    className="py-3 px-4 h-12 text-2xl font-bold rounded-sm mx-2 w-full inline-flex justify-center border-gray-300 shadow-sm 
                  bg-white text-gray-700 hover:bg-primary-hover-button sm:w-auto sm:text-sm"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Required props */}
        {/* valueDate,
            currentUser,
            organizationId,
            setMyAssigment, */}
        <div>
          {Array.isArray(myAssigments) &&
            myAssigments.map((value, index) => (
              <AssigmentCard
                key={index}
                currentUser={currentUser}
                organizationId={organizationId}
                setMyAssigment={setMyAssigment}
                valueDate={value}
                setAllAssigmentInfo={setAllAssigmentInfo}
                allAssigmentInfo={allAssigmentInfo}
              />
            ))}
        </div>
        {/* Required props */}
        {/* myAssigments,
            currentUser,
            organizationId,
            setMyAssigment,
            showModal,
            setShowModal, */}
        <AssigmentStatud
          currentUser={currentUser}
          organizationId={organizationId}
          setMyAssigment={setMyAssigment}
          showModal={showModal}
          setShowModal={setShowModal}
          myAssigments={myAssigments}
          allAssigmentInfo={allAssigmentInfo}
          setAllAssigmentInfo={setAllAssigmentInfo}
        />
      </div>
    </>
  );
};

const mapPropsToDispatch = (dispatch) => ({
  setMyAssigment: (assigments) => dispatch(setMyAssigment(assigments)),
});

const mapPropsToStage = (state) => ({
  myAssigments: selectMyAssigments(state),
  currentUser: selectCurrentUser(state),
  organizationId: selectedOrganizationId(state),
});

export default connect(mapPropsToStage, mapPropsToDispatch)(AssigmentPage);
