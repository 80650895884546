import QuestionType from "./questions-type";

export const setQuestionData = (data) => ({
  type: QuestionType.GET_QUESTION_LIST,
  payload: data,
});

export const setQuizCodeRedux = (code) => ({
  type: QuestionType.GET_C0DE,
  payload: code,
});

export const clearQuestion = (clearAnswer = true) => ({
  type: QuestionType.ClEAR_ANSWER,
  payload: clearAnswer,
});

export const setAppreactionMsg = (msg) => ({
  type: QuestionType.APPRECIATION_MESSAGE,
  payload: msg,
});

export const setQuestionResult = (result) => ({
  type: QuestionType.GET_QUESTION_RESULT,
  payload: result,
});
