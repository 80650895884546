//Config
import { API_URL } from "Config/config";
const quizBase = `${API_URL}organizations`;

export const setQuizCode = async ({
  code,
  companyId,
  size = 5,
  currentPage = 0,
}) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${API_URL}companies/${companyId}/questions/${code}?size=${size}&page=${currentPage}`,
    options
  )
    .then((response) => response)
    .catch((error) => error);
};

export const sendQuestionAnswers = ({
  companyId,
  isDone,
  newArray,
  quizzCode,
}) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      answers: newArray,
      isDone,
      quizzCode,
    }),
  };
  return fetch(`${API_URL}companies/${companyId}/answers`, options)
    .then((response) => response)
    .catch((error) => error);
};

export const addNewUserCode = async (organizationId, token, userData) => {
  const { names, lastNames, email, reference, phoneNumber } = userData;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      names,
      lastNames,
      phone: phoneNumber,
      email,
      reference,
    }),
  };
  return fetch(`${quizBase}/${organizationId}/leads`, options)
    .then((response) => response)
    .catch((error) => error);
};
