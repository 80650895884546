import typeOrganization from "./organization-type";

//set the organization seleted in organizations views
export const setOrganizationSeleted = (organization) => ({
  type: typeOrganization.SET_ORGANIZATION_SELECTED,
  payload: organization,
});
//clear organization redux
export const clearOrganization = () => ({
  type: typeOrganization.CLEAR_ORGANIZATION,
});
