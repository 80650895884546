//Libs
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
//Config
import { ROUTES, MOBILE_VIEWPORT_SIZE, DEVICE_IS_MOBILE } from "Config/config";

const StyledLogo = styled.img`
  width: 4vw;
  padding: 5px 0;
  cursor: pointer;
  filter: brightness(0) invert(1);
  opacity: 0.8;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    width: 25px;
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      width: 25px;
    `}
`;

export default function Logo({ organizationLogo }) {
  return (
    <Link to={ROUTES.HOME}>
      <StyledLogo src={organizationLogo} alt="logo" />
    </Link>
  );
}
